import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  useToast,
  Th,
  FormLabel,
  FormControl,
  Thead,
  Tr,
  HStack,
  useColorModeValue,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Radio,
  RadioGroup,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { MdReplay, MdCheck } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { RiEqualizerFill } from "react-icons/ri";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { TiTick } from "react-icons/ti";
import { SelectBar } from "components/Navbars/SelectBar/SelectBar";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaUndoAlt,
  FaPlus,
} from "react-icons/fa";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import axiosClient from "../../axiosClient";

function SearchTable3(props) {
  const toast = useToast();
  const { columnsData, tableData, onSave, anyId, setId, nombreSet } = props;

  const columns = useMemo(() => columnsData, []);
  const [data, setData] = useState(tableData);

  const [checkedState, setCheckedState] = useState({});
  const [checkedId, setCheckedId] = useState([]);
  const [keywordSetName, setKeywordSetName] = useState(""); // Estado para el campo de nombre del keywordset
  const [dateFilter, setDateFilter] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCheckboxChange = (id) => {
    setCheckedId((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
    setCheckedState((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    state,
  } = tableInstance;

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const postKeywordSet = async (keywordSet, method) => {
    try {
      let response;
      switch (method) {
        case "save":
          response = await axiosClient.post(
            `${process.env.REACT_APP_API_URL}/keywordset/`,
            {
              campaignId: campId,
              keywordId: keywordSet,
              name: keywordSetName,
            }
          );
          break;
        case "update":
          console.log(keywordSets);
          response = await axiosClient.patch(
            `${process.env.REACT_APP_API_URL}/keywordset/${setId}`,
            {
              campaignId: campId,
              keywordId: keywordSet,
              name: nombreSet,
            }
          );
          break;
        default:
          break;
      }

      setCheckedState({});
      setCheckedId([]);
      setKeywordSetName("");
      onSave(response.data);
      toast({
        title: "Keyword Set guardado",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      return response.data;
    } catch (error) {
      console.error("error guardando el keyword set:", error);
    }
  };
  const [keywordSetId, setKeywordSetId] = useState("");
  const [keywordSets, setKeywordSets] = useState([]);
  useEffect(() => {
    const fetchKeywordSets = async () => {
      try {
        const response = await axiosClient.get(
          `${process.env.REACT_APP_API_URL}/keywordset/campaign-id/${anyId}`
        );
        console.log("campañas", response.data);
        const filtrados = response.data.filter(
          (item) => item.name !== anyId.toString()
        );
        const keywordsets = filtrados.map((item) => ({
          id: item._id,
          name: item.name,
        }));
        setKeywordSets(keywordsets);
      } catch (error) {
        console.error("There was an error fetching the keywordsets:", error);
      }
    };
    if (anyId) fetchKeywordSets();
  }, []);

  const [campId, setCampId] = useState("");
  useEffect(() => {
    const lastValue = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    setCampId(lastValue);
    console.log("la data table", tableData);
    setPageSize(10);
  }, [setPageSize]);

  const { pageIndex, pageSize, globalFilter } = state;

  const applyDateFilter = (range) => {
    const currentDate = new Date();
    let filteredData = tableData;

    const getDateFromRange = (range) => {
      const newDate = new Date(currentDate);
      switch (range) {
        case "1d":
          newDate.setDate(currentDate.getDate() - 1);
          break;
        case "1w":
          newDate.setDate(currentDate.getDate() - 7);
          break;
        case "15d":
          newDate.setDate(currentDate.getDate() - 15);
          break;
        case "1m":
          newDate.setMonth(currentDate.getMonth() - 1);
          break;
        case "3m":
          newDate.setMonth(currentDate.getMonth() - 3);
          break;
        default:
          return null;
      }
      return newDate;
    };

    const dateThreshold = getDateFromRange(range);

    if (dateThreshold) {
      filteredData = tableData.filter(
        (item) => new Date(item.date) >= dateThreshold
      );
    }

    console.log("Filtered Data", filteredData);
    setData(filteredData);
    setDateFilter(range);
    onClose();
  };

  const clearDateFilter = () => {
    setData(tableData);
    setDateFilter(null);
  };

  const [resetTable, setResetTable] = useState(false);
  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex justify="space-between" align="center" w="100%" px="22px">
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ sm: "4px", md: "12px" }}
            align="center"
            me="12px"
            my="24px"
            minW={{ sm: "100px", md: "200px" }}
          >
            <Select
              borderColor="black"
              variant="main"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              color="gray.500"
              size="sm"
              borderRadius="12px"
              maxW="75px"
              cursor="pointer"
            >
              <option>5</option>
              <option>10</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option>500</option>
            </Select>
            <Text fontSize="xs" color="black.400" fontWeight="normal">
              Entradas por página
            </Text>
          </Stack>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ sm: "4px", md: "12px" }}
            align="center"
            me="12px"
            my="24px"
            minW={{ sm: "100px", md: "200px" }}
          >
            <Button
              onClick={() => postKeywordSet(checkedId, "update")}
              colorScheme="blue"
              padding="9px"
              size="md"
              width="100px"
              fontWeight="100"
            >
              <small>Actualizar</small>
            </Button>
            <Flex
              align={{ sm: "flex-start", lg: "flex-end" }}
              justify={{ sm: "flex-start", lg: "flex-end" }}
              w="100%"
              px="22px"
              mb="36px"
            >
              <SearchBar
                borderColor={"black"}
                border="1px solid"
                onChange={(e) => setGlobalFilter(e.target.value)}
                searchActive={false}
              />
              <IconButton
                icon={<RiEqualizerFill />}
                onClick={onOpen}
                ml="2"
                aria-label="Filter"
              />
              {dateFilter && (
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme="blue"
                  ml="4"
                >
                  <TagLabel>{`Filtrado: ${dateFilter}`}</TagLabel>
                  <TagCloseButton onClick={clearDateFilter} />
                </Tag>
              )}
            </Flex>
          </Stack>
        </Flex>
        {!resetTable && (
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead background="gray.200">
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      key={index}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="0px"
                      style={{ minWidth: column.minWidth, width: column.width }}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        style={{ fontWeight: "700" }}
                        color="black.400"
                      >
                        {column.render("Header")}
                        <Icon
                          w={{ sm: "10px", md: "14px" }}
                          h={{ sm: "10px", md: "14px" }}
                          color={column.isSorted ? "gray.500" : "gray.400"}
                          float="right"
                          as={
                            column.isSorted
                              ? column.isSortedDesc
                                ? TiArrowSortedDown
                                : TiArrowSortedUp
                              : TiArrowUnsorted
                          }
                        />
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                const rowId = row.original.id;
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    bg={checkedState[rowId] ? "blue.100" : "white"}
                  >
                    {row.cells.map((cell, index) => {
                      /*console.log(
                          checkedState[rowId],
                          row.original.id,
                          index,
                          cell
                        );*/
                      let data = "";
                      if (cell.column.Header === "ID") {
                        data = (
                          <Flex align="center">
                            <FormControl display="flex" alignItems="center">
                              <Checkbox
                                size="lg"
                                isChecked={
                                  checkedState[rowId] === undefined
                                    ? false
                                    : checkedState[rowId]
                                }
                                onChange={() => handleCheckboxChange(rowId)}
                                colorScheme="blue"
                                me="8px"
                              />
                              <FormLabel
                                mt="3"
                                style={{
                                  overflow: "hidden",
                                  maxWidth: "75px",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                              ></FormLabel>
                            </FormControl>
                          </Flex>
                        );
                      } else if (cell.column.Header === "DATE") {
                        data = (
                          <Text style={{ minWidth: "75px" }}>{cell.value}</Text>
                        );
                      } else if (cell.column.Header === "QUERY") {
                        data = (
                          <Flex align="center">
                            <Text
                              style={
                                checkedState[rowId]
                                  ? {
                                      fontWeight: "700",
                                      color: "black",
                                      minWidth: "175px",
                                    }
                                  : { fontWeight: "300", minWidth: "175px" }
                              }
                            >
                              {cell.value}
                            </Text>
                            {row.original.keywordsetId &&
                            row.original.keywordsetId.length > 0 ? (
                              <TiTick color={"green"} width="20px" />
                            ) : (
                              ""
                            )}
                          </Flex>
                        );
                      } else if (cell.column.Header === "CLICKS") {
                        data = <Text>{cell.value}</Text>;
                      } else if (cell.column.Header === "IMPR") {
                        data = <Text>{cell.value}</Text>;
                      } else if (cell.column.Header === "CTR") {
                        data = <Text>{parseFloat(cell.value).toFixed(2)}</Text>;
                      } else if (cell.column.Header === "POSITION") {
                        data = <Text>{parseFloat(cell.value).toFixed(2)}</Text>;
                      } else if (cell.column.Header === "IMP") {
                        data = <Text>{parseFloat(cell.value).toFixed(2)}</Text>;
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          style={{
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          }}
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          w="100%"
          px={{ md: "22px" }}
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: "24px", md: "0px" }}
          >
            Showing {pageSize * pageIndex + 1} to{" "}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{" "}
            of {tableData.length} entries
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="8px"
              bg="#fff"
              border="1px solid lightgray"
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                opacity: "0.7",
                borderColor: "gray.500",
              }}
            >
              <Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w="75px"
                mx="6px"
                defaultValue="1"
                onChange={(e) => gotoPage(e)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-effects"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="8px"
                    bg={pageNumber === pageIndex + 1 ? "blue.500" : "#fff"}
                    border={
                      pageNumber === pageIndex + 1
                        ? "none"
                        : "1px solid lightgray"
                    }
                    _hover={{
                      opacity: "0.7",
                      borderColor: "gray.500",
                    }}
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      color={pageNumber === pageIndex + 1 ? "#fff" : "gray.600"}
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant="no-effects"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="8px"
              bg="#fff"
              border="1px solid lightgray"
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
              _hover={{
                bg: "gray.200",
                opacity: "0.7",
                borderColor: "gray.500",
              }}
            >
              <Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
            </Button>
          </Stack>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Seleccionar Rango de Fechas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={applyDateFilter}>
              <Stack direction="column">
                <Radio value="1d">1 Día</Radio>
                <Radio value="1w">1 Semana</Radio>
                <Radio value="15d">15 Días</Radio>
                <Radio value="1m">1 Mes</Radio>
                <Radio value="3m">3 Meses</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SearchTable3;
