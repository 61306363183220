import React, { useRef } from "react";
import {
  IconButton,
  InputGroup,
  Select,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaArrowDown } from "react-icons/fa";

export function SelectBar({ options, value, onChange, ...rest }) {
  const iconColor = useColorModeValue("gray.700", "gray.200");
  const selectBg = useColorModeValue("white", "navy.800");

  return (
    <InputGroup borderRadius="8px" w="200px" {...rest}>
      <Select
        variant="filled"
        fontSize="xs"
        bg={selectBg}
        value={value}
        onChange={onChange}
        placeholder="Seleccionar..."
        style={{ cursor: "pointer" }}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </InputGroup>
  );
}
