import React, { useState, useEffect } from "react";
import { Checkbox, VStack, Text, Heading } from "@chakra-ui/react";

const CheckboxList = ({ data, setId, tabId }) => {
  const points = data.split("\n").filter((line) => line.match(/^\d+\.\s/));

  const storageKey = `checkboxListState-${setId}-${tabId}`;

  const loadStateFromLocalStorage = () => {
    const storedState = localStorage.getItem(storageKey);
    return storedState
      ? JSON.parse(storedState)
      : points.map((point) => ({ point, isChecked: false }));
  };

  const [checkedItems, setCheckedItems] = useState(loadStateFromLocalStorage);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(checkedItems));
  }, [checkedItems, storageKey]);

  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index].isChecked = !updatedCheckedItems[index]
      .isChecked;
    setCheckedItems(updatedCheckedItems);
  };

  const formatPoint = (point) => {
    return point.replace(/\*\*(.*?)\*\*/, "<strong>$1</strong>");
  };

  return (
    <VStack align="start">
      <Heading as="h2" size="md">
        Puntos importantes:
      </Heading>
      {points.map((point, index) => (
        <Checkbox
          key={index}
          isChecked={checkedItems[index].isChecked}
          onChange={() => handleCheckboxChange(index)}
        >
          <Text dangerouslySetInnerHTML={{ __html: formatPoint(point) }} />
        </Checkbox>
      ))}
    </VStack>
  );
};

export default CheckboxList;
