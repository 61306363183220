import React, { useEffect, useState, useRef } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
import {
  Avatar,
  Badge,
  Button,
  Box,
  Checkbox,
  Flex,
  Grid,
  Icon,
  Progress,
  Stack,
  HStack,
  Stat,
  Tooltip,
  StatHelpText,
  StatLabel,
  StatNumber,
  SimpleGrid,
  Text,
  useColorMode,
  useColorModeValue,
  CloseButton,
  useToast,
} from "@chakra-ui/react";
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  HomeIcon,
  WalletIcon,
} from "components/Icons/Icons";
import { BsCircleFill } from "react-icons/bs";
import { FaKey, FaUsers, FaGlobe, FaPlane } from "react-icons/fa";
import axiosClient from "axiosClient";
import { useHistory } from "react-router-dom";

const CampaignCard = ({ campaign, onDelete }) => {
  const history = useHistory();
  const toast = useToast();
  useEffect(() => {
    //console.log(campaign);
  }, []);

  const deleteCampaign = (id) => {
    if (window.confirm("Seguro que quieres eliminar la campaña?")) {
      axiosClient.delete(`/campaign/${id}`).then((res) => {
        if (res.data === true) {
          onDelete();
          toast({
            title: "Campaña eliminada",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    }
  };

  const hasKeywords = campaign.keywordsetId && campaign.keywordsetId[0];
  const hasMultipleUsers =
    campaign.projectId.users && campaign.projectId.users.length > 1;
  const hasWebsites =
    campaign.projectId.websites && campaign.projectId.websites?.length > 0;
  const hasLanding = campaign.landingId && campaign.landingId?.length > 0;

  const renderKeywordsTooltip = () => {
    const queries = campaign.keywordsetId.flatMap((set) =>
      set.keywordId.map((keyword) => keyword.query).join(", ")
    );
    return queries;
  };

  const renderWebsitesTooltip = () => {
    return campaign.projectId.websites.map((site) => site.url).join(", ");
  };

  const rendeLandingsTooltip = () => {
    return campaign.landingId.map((landing) => landing.title).join(", ");
  };

  const handleCmapaignClick = (id) => {
    history.push(`/admin/pages/campaign/edit-campaign/${id}`);
  };

  const { colorMode } = useColorMode();
  const textColor = useColorModeValue("gray.700", "white");
  const iconBoxInside = useColorModeValue("white", "white");
  const iconBlue = useColorModeValue(`${campaign.color}`, `${campaign.color}`);
  const bgSalaryCard = useColorModeValue("gray.50", "navy.900");
  const bgButton = useColorModeValue("gray.700", "blue.500");
  return (
    <Card
      mb="24px"
      maxW={{ sm: "330px", md: "100%" }}
      minHeight="200px"
      px={{ sm: "0px", lg: "22px" }}
    >
      <CardHeader>
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={() => {
            deleteCampaign(campaign._id);
          }}
        />
        <Flex
          direction="column"
          w="100%"
          style={{ cursor: "pointer" }}
          onClick={() => handleCmapaignClick(campaign._id)}
        >
          <Flex justify="space-between" w="100%" px={{ sm: "22px", lg: "0px" }}>
            <Flex align="center">
              <IconBox h={"45px"} w={"45px"} bg={iconBlue} me="16px">
                <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
              <Flex direction="column">
                <Text color={textColor} fontWeight="bold" fontSize="l">
                  {campaign.nombre}
                </Text>
                <Text color="gray.400" fontWeight="normal" fontSize="md">
                  {campaign.objetivo}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody p={{ sm: "0px", lg: "22px" }} position="relative">
        <Box position="absolute" right="10px" bottom="10px">
          <Flex gap="10px" style={{ cursor: "pointer" }}>
            <Tooltip
              label={hasKeywords ? renderKeywordsTooltip() : "No keywords"}
              portalProps={{ appendToParentPortal: true }}
              hasArrow
            >
              <Box>
                <Icon as={FaKey} color={hasKeywords ? "black" : "gray.400"} />
              </Box>
            </Tooltip>
            <Tooltip
              label={hasMultipleUsers ? "Multiple users" : "Single user"}
              portalProps={{ appendToParentPortal: true }}
              hasArrow
            >
              <Box>
                <Icon
                  as={FaUsers}
                  color={hasMultipleUsers ? "black" : "gray.400"}
                />
              </Box>
            </Tooltip>
            <Tooltip
              label={hasWebsites ? renderWebsitesTooltip() : "No websites"}
              portalProps={{ appendToParentPortal: true }}
              hasArrow
            >
              <Box>
                <Icon as={FaGlobe} color={hasWebsites ? "black" : "gray.400"} />
              </Box>
            </Tooltip>
            <Tooltip
              label={hasLanding ? rendeLandingsTooltip() : "No hay landings"}
              portalProps={{ appendToParentPortal: true }}
              hasArrow
            >
              <Box>
                <Icon as={FaPlane} color={hasLanding ? "black" : "gray.400"} />
              </Box>
            </Tooltip>
          </Flex>
        </Box>
      </CardBody>
      {/*<Progress colorScheme="blue" size="sm" py= borderRadius="15px" value={60} />*/}
    </Card>
  );
};

export default CampaignCard;
