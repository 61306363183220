import {
  Box,
  FormControl,
  FormLabel,
  Select,
  RadioGroup,
  Radio,
  Stack,
  Button,
} from "@chakra-ui/react";

function BlogPostSettings({ onChange, onSubmit, loading }) {
  return (
    <Box p={4}>
      <FormControl mt={4}>
        <FormLabel htmlFor="palabras">Cantidad de Palabras</FormLabel>
        <Select
          id="palabras"
          defaultValue={300}
          placeholder="Selecciona la cantidad"
          onChange={(e) => onChange("palabras", e.target.value)}
        >
          {[300, 500, 1000, 1500, 2000].map((wordCount) => (
            <option value={wordCount}>{wordCount}</option>
          ))}
        </Select>
      </FormControl>

      <FormControl mt={4}>
        <FormLabel htmlFor="subtitulos">Cantidad de Subtítulos H2</FormLabel>
        <Select
          id="subtitulos"
          defaultValue={3}
          placeholder="Selecciona la cantidad"
          onChange={(e) => onChange("subtitulos", e.target.value)}
        >
          {[3, 4, 5, 6, 7, 8, 9, 10].map((h2Count) => (
            <option value={h2Count}>{h2Count}</option>
          ))}
        </Select>
      </FormControl>

      <FormControl isRequired>
        <FormLabel htmlFor="parrafos">Número de Párrafos por H2</FormLabel>
        <Select
          id="parrafos"
          defaultValue={1}
          placeholder="Selecciona la cantidad"
          onChange={(e) => onChange("parrafos", e.target.value)}
        >
          {[1, 2, 3].map((n) => (
            <option value={n}>{n}</option>
          ))}
        </Select>
      </FormControl>

      <FormControl as="fieldset" mt={4}>
        <FormLabel as="legend">Tabla de Contenidos</FormLabel>
        <RadioGroup
          defaultValue="No"
          onChange={(e) => onChange("tablaDeContenidos", e)}
        >
          <Stack spacing={5} direction="row">
            <Radio value="Si">Sí</Radio>
            <Radio value="No">No</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <Button mt={4} colorScheme="blue" onClick={onSubmit} isLoading={loading}>
        Enviar
      </Button>
    </Box>
  );
}

export default BlogPostSettings;
