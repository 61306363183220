import React, { useState } from "react";
import { FaPlane } from "react-icons/fa";
import { MdArrowForwardIos } from "react-icons/md";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Tag,
  TagCloseButton,
  VStack,
  HStack,
  Button,
  Icon,
  Text,
  Spinner,
} from "@chakra-ui/react";
import EditSetsAccordionItem from "components/Custom/EditSetsAccordionItem";

const EditSetsAcordion = ({
  cargandoSets,
  keywordSet,
  tableData,
  lasLandings,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  return (
    <>
      {!cargandoSets && keywordSet ? (
        keywordSet.map((set, index) => (
          <Accordion key={set._id + index} allowToggle>
            {set.keywordId.length > 0 && (
              <EditSetsAccordionItem
                set={set}
                lasLandings={lasLandings}
                tableData={tableData}
                onUpdated={() => handleUpdate()}
              />
            )}
          </Accordion>
        ))
      ) : cargandoSets && keywordSet ? (
        <Spinner />
      ) : (
        <Text>No hay sets de keywords</Text>
      )}
    </>
  );
};

export default EditSetsAcordion;
