import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  useToast,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Select,
  HStack,
} from "@chakra-ui/react";
import CheckboxList from "./CheckboxList";
import axiosClient from "../../axiosClient";
import Markdown from "markdown-to-jsx";

const ReferenciasForm = ({ setId }) => {
  const toast = useToast();
  const [urls, setUrls] = useState([{ url: "", analyzed: false }]);
  const [procesando, setProcesando] = useState(false);
  const [sinopsis, setSinopsis] = useState("");
  const [hasSavedUrls, setHasSavedUrls] = useState(false);

  const handleRemoveUrl = (index) => {
    const newUrls = [...urls];
    newUrls.splice(index, 1);
    setUrls(newUrls);
  };

  const handleAddUrl = () => {
    setUrls([...urls, { url: "", analyzed: false }]);
    setHasSavedUrls(false);
  };

  const fetchUrls = async () => {
    try {
      const response = await axiosClient.get(
        `keywordset/${setId}/reference-url/`
      );
      console.log(response.data);

      const urlsFromServer = response.data.length
        ? response.data
        : [{ url: "", analyzed: false }];
      setUrls(urlsFromServer);
      if (response.data.length > 0) {
        setHasSavedUrls(true); // Si hay URLs traídas del servidor, muestra el botón
      }
    } catch (error) {
      toast({
        title: "Error al cargar las referencias" + error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchSinopsis = async () => {
    try {
      const response = await axiosClient.get(`keywordset/${setId}/sinopsis/`);
      console.log(response.data);
      setSinopsis(response.data || "");
    } catch (error) {
      toast({
        title: "Error al cargar las referencias",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchUrls();
    fetchSinopsis();
  }, [setId]);

  const handleSaveUrls = async () => {
    try {
      // Eliminar URLs duplicadas
      const uniqueUrlsMap = new Map();
      urls.forEach((urlObj) => {
        uniqueUrlsMap.set(urlObj.url, urlObj);
      });
      const uniqueUrls = Array.from(uniqueUrlsMap.values());

      await axiosClient.post(`keywordset/${setId}/reference-url/`, {
        referenceUrls: uniqueUrls,
      });
      toast({
        title: "Referencias guardadas",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setHasSavedUrls(true);
    } catch (error) {
      toast({
        title: "Error al guardar las referencias",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUrlChange = (index, value) => {
    const newUrls = [...urls];
    newUrls[index].url = value;
    setUrls(newUrls);
  };

  const handleBlur = (index) => {
    const newUrls = [...urls];
    const url = newUrls[index].url;
    if (!url.startsWith("https://")) {
      newUrls[index].url = "https://" + url;
      setUrls(newUrls);
    }
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const handleAnalizar = async () => {
    try {
      setProcesando(true);
      handleSaveUrls();
      const response = await axiosClient.post(`research/screenshot/${setId}/`);
      if (isEmpty(response.data)) {
        toast({
          title: "Ya fueron analizadas",
          description: "Nada nuevo",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setSinospis(response.data.message);
        console.log(response.data.message);
        toast({
          title: "Análisis en proceso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      setProcesando(false);
    } catch (error) {
      setProcesando(false);

      toast({
        title: "Error al analizar",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    fetchSinopsis();
    console.log(sinopsis);
  };

  return (
    <>
      <Box>
        <Text p={3}>¿Quieres agregar algún sitio/url de referencia?</Text>
        {urls.length > 0 &&
          urls.map((urlObj, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Input
                type="text"
                value={urlObj.url}
                onChange={(e) => handleUrlChange(index, e.target.value)}
                onBlur={() => handleBlur(index)}
              />
              <Button onClick={() => handleRemoveUrl(index)} ml={2}>
                -
              </Button>
            </Box>
          ))}
        <Button onClick={handleAddUrl} mt={2}>
          +
        </Button>
        <Button onClick={handleSaveUrls} mt={2}>
          Guardar
        </Button>{" "}
        {hasSavedUrls && (
          <Button
            onClick={handleAnalizar}
            my={7}
            colorScheme="green"
            isLoading={procesando}
          >
            Procesar URLs
          </Button>
        )}
        {sinopsis.length > 0 && (
          <Tabs>
            <TabList>
              {sinopsis.map((item, index) => (
                <Tab key={index}>Referencia {index + 1}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {sinopsis.map((item, index) => (
                <TabPanel key={index}>
                  <Text>
                    <CheckboxList data={item} setId={setId} tabId={index} />
                  </Text>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </Box>
    </>
  );
};

export default ReferenciasForm;
