import React, { useEffect, useState, useRef } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import IconBox from "components/Icons/IconBox";
import {
  Avatar,
  Badge,
  Button,
  Box,
  Checkbox,
  Flex,
  Grid,
  Icon,
  Progress,
  Stack,
  HStack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  SimpleGrid,
  Link,
  Text,
  useColorMode,
  useColorModeValue,
  CloseButton,
  useToast,
} from "@chakra-ui/react";
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  HomeIcon,
  WalletIcon,
} from "components/Icons/Icons";
import { BsCircleFill } from "react-icons/bs";
import axiosClient from "axiosClient";

const LandingCard = ({ landing, onDelete }) => {
  const toast = useToast();
  useEffect(() => {
    //console.log("la landing", landing);
  }, []);

  const deleteLanding = (id) => {
    if (window.confirm("Seguro que quieres eliminar la landing?")) {
      axiosClient.delete(`/landings/${id}`).then((res) => {
        if (res.data === true) {
          onDelete();
          toast({
            title: "Campaña eliminada",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    }
  };

  const { colorMode } = useColorMode();
  const textColor = useColorModeValue("gray.700", "white");
  const iconBoxInside = useColorModeValue("white", "white");
  const iconBlue = useColorModeValue("gray.700", "white");
  /*const iconBlue = useColorModeValue(
    `${landing.campaignId.color}`,
    `${landing.campaignId.color}`
  );*/
  const bgSalaryCard = useColorModeValue("gray.50", "navy.900");
  const bgButton = useColorModeValue("gray.700", "blue.500");
  return (
    <Card
      mb="24px"
      maxW={{ sm: "330px", md: "100%" }}
      px={{ sm: "0px", lg: "22px" }}
    >
      <CardHeader>
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={() => deleteLanding(landing._id)}
        />
        <Flex direction="column" w="100%">
          <Flex justify="space-between" w="100%" px={{ sm: "22px", lg: "0px" }}>
            <Flex align="center">
              <IconBox h={"45px"} w={"45px"} bg={iconBlue} me="16px">
                <DocumentIcon h={"24px"} w={"24px"} color="white" />
              </IconBox>
              <Flex direction="column">
                <Text color="gray.400" fontWeight="normal" fontSize="sm">
                  {landing.title}
                </Text>
                <Text color={textColor} fontWeight="bold" fontSize="lg">
                  {landing.campaignId && landing.campaignId?.nombre}
                </Text>
                <Link href={landing.url} isExternal color={bgButton}>
                  Ver Landing
                </Link>
              </Flex>
            </Flex>
          </Flex>
          <Text fontSize="sm" color="gray.500" mt="2">
            {landing.description}
          </Text>
          <Link
            href={landing.url}
            isExternal
            color={bgButton}
            fontWeight="bold"
          >
            Visitar sitio: {landing.url}
          </Link>
          <Text color="gray.600" fontSize="sm" mt="1">
            Propietario: {landing.userId.username}
          </Text>
        </Flex>
      </CardHeader>
    </Card>
  );
};
export default LandingCard;
