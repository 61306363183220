import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Select,
  Flex,
  IconButton,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import axiosClient from "../../axiosClient.js";

const LandingForm = ({ userId, projectId }) => {
  const [projects, setProjects] = useState([]);
  const [landings, setLandings] = useState([
    { url: "", projectId: "", campaignId: "", userId: userId },
  ]);

  const toast = useToast();

  useEffect(() => {
    if (projectId) {
      handleChange(0, "projectId", projectId);
    }
    axiosClient
      .get(
        `${process.env.REACT_APP_API_URL}/projects/findByCreatedBy/${userId}`
      )
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        toast({
          title: "Error loading projects",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }, []);

  const handleSaveLandings = () => {
    console.log(landings);
    axiosClient
      .post(`${process.env.REACT_APP_API_URL}/landings/create/`, landings)
      .then(() => {
        toast({
          title: "Landings saved successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Error saving landings",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const addLanding = () => {
    setLandings([
      ...landings,
      { url: "", projectId: projectId || "", campaignId: "", userId: userId },
    ]);
  };

  const handleChange = (index, field, value) => {
    const newLandings = [...landings];
    newLandings[index][field] = value;
    setLandings(newLandings);
  };

  return (
    <Box p={5}>
      {landings.map((landing, index) => (
        <Stack spacing={3} key={index}>
          <Input
            placeholder="Landing URL"
            value={landing.url}
            onChange={(e) => handleChange(index, "url", e.target.value)}
          />
          <Select
            placeholder="Select project"
            value={
              localStorage.projectId
                ? localStorage.projectId
                : landing.projectId
            }
            defaultValue={projectId}
            onChange={(e) => handleChange(index, "projectId", e.target.value)}
          >
            {projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.name}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Select campaign"
            value={landing.campaignId}
            onChange={(e) => handleChange(index, "campaignId", e.target.value)}
            isDisabled={!landing.projectId}
          >
            {landing.projectId &&
              projects
                .find((p) => p._id === landing.projectId)
                ?.campaigns.map((campaign) => (
                  <option key={campaign._id} value={campaign._id}>
                    {campaign.nombre}
                  </option>
                ))}
          </Select>
        </Stack>
      ))}
      <Flex justify="center" mt={4}>
        <IconButton
          aria-label="Add landing"
          icon={<AddIcon />}
          onClick={addLanding}
        />
      </Flex>
      <Button colorScheme="blue" mt={4} onClick={() => handleSaveLandings()}>
        Guardar Landings
      </Button>
    </Box>
  );
};

export default LandingForm;
