import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Stack,
  Select,
  VStack,
  IconButton,
  useToast,
  Container,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import axiosClient from "../../../axiosClient.js";
import LandingForm from "components/Custom/LandingForm";

function NewLandings() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [projectId, setProjectId] = useState(
    localStorage.getItem("projectId") || null
  );

  return (
    <Container
      maxW="container.md"
      marginTop="135px"
      background="white"
      borderRadius="12px"
      paddingTop="21px"
    >
      <LandingForm userId={userId} projectId={projectId} />
    </Container>
  );
}

export default NewLandings;
