import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  VStack,
  HStack,
  Select,
  SimpleGrid,
  IconButton,
  useToast,
  Container,
  Heading,
  Text,
  useColorModeValue,
  Flex,
  Tag,
  Spinner,
  TagLabel,
  TagCloseButton,
  Tooltip,
  UnorderedList,
  Icon,
  ListItem,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { TiTick } from "react-icons/ti";
import { useParams } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import EditCampaignTabs from "components/Custom/EditCampaignTabs";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { FaPlane, FaPlus, FaMinus } from "react-icons/fa";
import { SelectBar } from "components/Navbars/SelectBar/SelectBar";
import axiosClient from "../../../axiosClient.js";
import { Tabs, Tab, TabList, TabPanel, TabPanels } from "@chakra-ui/react";

function EditCampaign() {
  const [tableData3, settableData3] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState({
    nombre: "",
    objetivo: "",
    estado: 0,
    projectId: id,
    userId: localStorage.userId,
    keywordsetId: {
      keywordId: [],
    },
    landingId: [],
    blogposturl: "",
  });

  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();

  const handleCampaignNameChange = (e) =>
    setCampaign({ ...campaign, nombre: e.target.value });
  const handleCampaignObjChange = (e) =>
    setCampaign({ ...campaign, objetivo: e.target.value });
  const handleEstadoChange = (e) =>
    setCampaign({ ...campaign, estado: parseInt(e.target.value) });

  const [projectsData, setProjectsData] = useState([{ name: "", id: "" }]);
  const [selectedProject, setSelectedProject] = useState("");

  const [investigateResult, setInvestigateResult] = useState({});
  const [loadingInvestigation, setLoadingInvestigation] = useState(false);

  const handleSelectProject = (e) =>
    setCampaign({ ...campaign, projectId: e.target.value });

  //esto tiene que actualizar la campaña con el set de keywords
  const handleSubmit = async () => {
    // console.log(campaign);
    setIsLoading(true);
    try {
      await axiosClient.patch(
        `${process.env.REACT_APP_API_URL}/campaign/update/${id}`,
        {
          nombre: campaign.nombre,
          objetivo: campaign.objetivo,
          estado: campaign.estado,
          id,
        }
      );
      toast({
        title: campaign.nombre + " updated successfully.",
        description: "We've updated your campaign data.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error saving " + campaign.nombre,
        description: "There was an error saving your campaign.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [keywordSet, setKeywordSet] = useState({});
  const [cargandoSets, setCargandoSets] = useState(true);

  // Cargar los datos de la campaña si el ID es válido
  const [lasLandings, setLasLandings] = useState([]);
  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await axiosClient.get(
          `${process.env.REACT_APP_API_URL}/campaign/${id}`
        );
        // console.log("que onda aca", response.data);
        setCampaign({
          ...campaign,
          nombre: response.data.nombre,
          objetivo: response.data.objetivo,
        });
        const tmp = response.data.landingId;
        setLasLandings(
          tmp.map((item) => ({
            id: item._id,
            name: item.url,
          }))
        );
        console.log(campaign);
        //  console.log(tmp);
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };

    if (id !== ":id") {
      localStorage.setItem("campaignId", id);
      fetchCampaignData();
    }

    const fetchkeywordSetMainData = async () => {
      try {
        const response = await axiosClient.get(
          `${process.env.REACT_APP_API_URL}/keywordset/campaign-name/${id}`
        );
        //  console.log(response.data);
        const keywordId = response.data.keywordId;
        const mappedData = keywordId.map((item) => {
          // Calcula la importancia como mencionaste
          const importance =
            item.impressions * item.position * (item.clicks + 1);

          // Extrae la fecha de la propiedad 'query'
          const date = item.query.match(/\d{4}-\d{2}-\d{2}$/)[0];

          return {
            id: item._id,
            query: item.query.replace(date, "").trim(), // Remover la fecha del query y trim espacios extra
            clicks: item.clicks,
            impressions: item.impressions,
            ctr: item.ctr,
            position: item.position,
            importance: importance,
            date: date,
            keywordsetId: item.keywordsetId,
          };
        });
        //  console.log("la data mapeada", mappedData);
        settableData3(mappedData);
        // setCampaign(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (id !== ":id") fetchkeywordSetMainData();
  }, [id]);

  // Cargar los proyectos creados por el usuario si se trata de una nueva campaña
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (id === ":id") {
      axiosClient
        .get(
          `${process.env.REACT_APP_API_URL}/projects/findByCreatedBy/${userId}`
        )
        .then((response) => {
          const projects = response.data.map((project) => ({
            id: project._id,
            name: project.name,
          }));
          setProjectsData(projects);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  const [selectedValue, setSelectedValue] = useState("");

  const handleAddLanding = () => {
    window.location.href = "/admin/pages/landing/new-landing/";
  };

  //TODO: hacer mas prolijo el add y remove landings desde edit campaing

  const handleDeleteLanding = () => {
    window.location.href = "/admin/pages/landing/dashboard/";
  };

  return (
    <Box
      mt="135px"
      bg="white"
      borderRadius="12px"
      pt="21px"
      padding="35px 23px"
    >
      <FormControl id="campaign-name">
        <FormLabel>Campaign Name</FormLabel>
        <Input
          value={campaign.nombre}
          onChange={handleCampaignNameChange}
          placeholder="Enter Campaign name"
        />
      </FormControl>
      <FormControl id="campaign-objetivo" mt={4}>
        <FormLabel>Objetivo</FormLabel>
        <Input
          value={campaign.objetivo}
          onChange={handleCampaignObjChange}
          placeholder="Enter Campaign objective"
        />
      </FormControl>

      {id === ":id" && (
        <FormControl mt={4}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            <Select onChange={handleSelectProject}>
              <option value={0}>Select a project</option>
              {projectsData.map((project, index) => (
                <option key={project.id + index} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Select>
          </FormLabel>
        </FormControl>
      )}
      <HStack mt={"35px"}>
        <small>landings de la campaña:</small>
        <Icon as={FaPlane} color={"black"} />
        <SelectBar
          options={lasLandings}
          onChange={(e) => setSelectedValue(e.target.value)}
        />
        <IconButton
          variant="no-effects"
          icon={<FaPlus />}
          color="black"
          border="1px solid black"
          onClick={() => handleAddLanding()}
        />
        <IconButton
          variant="no-effects"
          icon={<FaMinus />}
          color="black"
          border="1px solid black"
          onClick={() => handleDeleteLanding()}
        />
      </HStack>
      <Button
        mt={4}
        colorScheme="green"
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        Actualizar
      </Button>

      <EditCampaignTabs
        id={id}
        tableData={tableData3}
        lasLandings={lasLandings}
      />
    </Box>
  );
}

export default EditCampaign;
