// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Switch,
  Text,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
// Assets
import { Link as RouterLink, useHistory } from "react-router-dom";

import CoverImage from "assets/img/CoverImage.png";
import React, { useState } from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import AuthCover from "layouts/AuthCover";
import axios from "axios";

function SignInCover() {
  const history = useHistory();
  // Chakra color mode
  const textColor = useColorModeValue("gray.400", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
    expiresIn: "120m",
  });
  const login = (loginData) => {
    setLoading(true);
    // console.log(process.env.REACT_APP_API_URL + "/auth/login");
    axios
      .post(process.env.REACT_APP_API_URL + "/auth/login", loginData)
      .then((response) => {
        const token = response.data.access_token;
        // Save token in local storage
        localStorage.setItem("token", token);
        // Save token as a cookie
        document.cookie = `token=${token}; path=/`;
        setTimeout(() => {
          setLoading(false);
          history.push("/admin/pages/projects/dashboard");
        }, 3000);
      })
      .catch((error) => {
        console.error("Login failed:", error);
        setLoading(false);
      });
  };
  // Manejador para cambiar el estado
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
    setLoginData({ ...loginData, expiresIn: rememberMe ? "43200m" : "60m" });
  };

  return (
    <AuthCover image={CoverImage}>
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt={{ base: "60px", md: "160px" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          mb={{ base: "20px", md: "auto" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Username
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Your full name"
              mb="24px"
              size="lg"
              onChange={(e) => {
                setLoginData({ ...loginData, username: e.target.value });
              }}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="password"
              placeholder="Your password"
              mb="24px"
              size="lg"
              onChange={(e) => {
                setLoginData({ ...loginData, password: e.target.value });
              }}
            />
            <FormControl display="flex" alignItems="center" mb="24px">
              <Switch
                id="remember-login"
                colorScheme="blue"
                me="10px"
                isChecked={rememberMe}
                onChange={handleRememberMeChange}
              />
              <FormLabel htmlFor="remember-login" mb="0" fontWeight="normal">
                Remember me
              </FormLabel>
            </FormControl>
            <Button
              fontSize="10px"
              variant="dark"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={() => login(loginData)}
              disabled={loading} // Disable the button when loading is true
            >
              {loading ? <Spinner size="sm" color="white" /> : "SIGN IN"}
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColor} fontWeight="medium">
              Don’t have an account?
              <RouterLink
                to="/auth/authentication/sign-up/cover"
                style={{
                  color: titleColor,
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                Sign up
              </RouterLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </AuthCover>
  );
}

export default SignInCover;
