import React, { useState, useEffect } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Box,
  List,
  ListItem,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import axiosClient from "axiosClient";

export function SearchBar(props) {
  const { variant, children, searchActive, ...rest } = props;
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("white", "navy.800");
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (searchTerm && searchActive) {
      const fetchData = async () => {
        try {
          const response = await axiosClient.get(
            `${process.env.REACT_APP_API_URL}/campaign/search?query=${searchTerm}`
          );
          setResults(response.data);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
      };
      fetchData();
    } else {
      setResults([]);
    }
  }, [searchTerm]);

  return (
    <Box position="relative" {...rest}>
      <InputGroup borderRadius="8px" w="200px">
        <InputLeftElement
          children={
            <IconButton
              bg="inherit"
              borderRadius="inherit"
              _hover={{}}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
            ></IconButton>
          }
        />
        <Input
          variant="search"
          fontSize="xs"
          bg={inputBg}
          placeholder="Type here..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      {results.length > 0 && (
        <List
          position="absolute"
          top="100%"
          left="0"
          right="0"
          bg="white"
          zIndex="10"
          borderRadius="8px"
          boxShadow="md"
        >
          {results.map((result) => (
            <ListItem key={result._id} p="2">
              <a href={`/admin/pages/campaign/edit-campaign/${result._id}`}>
                {result.nombre}
              </a>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}
