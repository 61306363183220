import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Avatar,
  Badge,
  Button,
  Box,
  Checkbox,
  Flex,
  Grid,
  Icon,
  Progress,
  Stack,
  HStack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  SimpleGrid,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar4 from "assets/img/avatars/avatar4.png";
import basicDark from "assets/img/BgMusicCard.png";
import basicBlue from "assets/img/basic-auth.png";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  HomeIcon,
  WalletIcon,
} from "components/Icons/Icons";
// Custom components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import DonutChart from "components/Charts/DonutChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator } from "components/Separator/Separator";
import {
  donutChartDataGeneral,
  donutChartOptionsGeneral,
  lineChartOptionsGeneral,
} from "variables/charts";
import { jwtDecode } from "jwt-decode";
import axiosClient from "axiosClient";
//import CampaignComponent from "components/Custom/CampaignComponent";
import CampaignCard from "components/Custom/CampaignCard";

function CampaignDashboard() {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const iconBoxInside = useColorModeValue("white", "white");
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const bgSalaryCard = useColorModeValue("gray.50", "navy.900");
  const bgButton = useColorModeValue("gray.700", "blue.500");
  const [username, setUsername] = useState("");
  const [ID, setID] = useState("");
  const [token, setToken] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [keywordSetNumber, setKeywordSetNumber] = useState(0);

  const lineChartDataGeneral = [
    {
      name: "Grupos de keywords",
      data: [30, 32, 27, 30, 35, 30, 25, 33, 27],
    },
  ];

  const fetchAllCampaigns = async () => {
    if (ID !== "")
      axiosClient
        .get(`${process.env.REACT_APP_API_URL}/campaign/user/${ID}`)
        .then((response) => {
          // console.log("campañas", response.data);
          let filtrados;
          if (localStorage.projectId) {
            filtrados = response.data.filter(
              (campaign) => campaign.projectId._id === localStorage.projectId
            );
          } else {
            filtrados = response.data;
          }
          //    console.log("filtrados", filtrados);
          const tmp = filtrados.map((campaign) => campaign.keywordsetId.length);
          const sum = tmp.reduce((acc, curr) => acc + curr, 0);
          //      console.log("Sum:", sum);
          //    console.log("tmp", tmp);
          setKeywordSetNumber(sum);
          setCampaigns(filtrados);
        });
  };

  const handleReRender = () => {
    fetchAllCampaigns();
  };

  useEffect(() => {
    try {
      // Desde la cookie
      if (document.cookie !== undefined && document.cookie !== "") {
        const tokenFromCookie = document.cookie
          .split("; ")
          .find((row) => row.startsWith("token="))
          .split("=")[1];
        const decodedToken = jwtDecode(tokenFromCookie);
        if (decodedToken.exp < new Date().getTime() / 1000) {
          history.push("/auth");
        }
        //   console.log(decodedToken);
        localStorage.setItem("userId", decodedToken.sub);
        setID(decodedToken.sub);
        setUsername(decodedToken.username);
        setToken(tokenFromCookie);
        fetchAllCampaigns();
      }
    } catch (error) {
      console.log(error);
    }
  }, [ID, token]);

  const handleCmapaignClick = (id, name) => {
    // console.log("que onda", id, name);
    history.push(`/admin/pages/campaign/edit-campaign/${campaign._id}`);
  };

  function Fecha() {
    const today = new Date();
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = today.toLocaleDateString("es-ES", options);
    return formattedDate;
  }
  // Desde localStorage

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Grid
        templateColumns={{
          sm: "1fr",
          lg: "0.5fr 1.5fr 1fr",
          xl: "1fr 1.7fr 1.5fr",
        }}
        gap="24px"
        mb="24px"
      >
        <Card>
          <CardHeader mb="28px">
            <Flex>
              <Avatar
                src={
                  "https://api.dicebear.com/8.x/bottts/svg?seed=" +
                  localStorage.userId
                }
                w="40px"
                h="40px"
                mr="15px"
                background="transparent"
              />
              <Flex direction="column">
                <Text color={textColor} fontSize="md" fontWeight="bold">
                  {username}
                </Text>
                <Text color="gray.400" fontSize="sm" fontWeight="normal">
                  2h ago
                </Text>
              </Flex>
            </Flex>
          </CardHeader>

          <Flex direction="column" w="100%">
            <Flex direction="column" maxW="400px" mb="32px">
              <Text color={textColor} fontSize="lg" fontWeight="bold" mb="4px">
                CTO.
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Cheef Technology Officer
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.85fr 1fr", xl: "70% 30%" }}
        gap="24px"
      >
        <Card p={{ sm: "16px", md: "22px" }} maxW={{ sm: "330px", md: "100%" }}>
          <CardHeader>
            <Flex justify="space-between" w="100%">
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                Tus Campañas
              </Text>

              <Text color="black.400" fontSize="md" fontWeight="normal">
                <HStack spacing="10px">
                  <Text
                    color="gray.400"
                    style={{ fontAlign: "right" }}
                    fontSize="md"
                    fontWeight="normal"
                  >
                    Fecha de hoy
                  </Text>
                  <Box as="span">{Fecha()}</Box>
                </HStack>
              </Text>
            </Flex>
          </CardHeader>

          <Flex
            direction="column"
            w="100%"
            overflowX={{ sm: "scroll", md: "hidden" }}
          >
            <SimpleGrid columns={2} spacing="24px">
              {campaigns.map((campaign) => (
                <CampaignCard
                  key={campaign._id}
                  campaign={campaign}
                  onDelete={handleReRender}
                />
              ))}
            </SimpleGrid>
            {/*ID !== "" && <CampaignComponent id={ID} />*/}
          </Flex>
        </Card>
        {/*}
        <Flex direction="column">
          <Card
            mb="24px"
            maxW={{ sm: "330px", md: "100%" }}
            px={{ sm: "0px", lg: "22px" }}
          >
            <CardHeader>
              <Flex direction="column" w="100%">
                <Flex
                  justify="space-between"
                  w="100%"
                  px={{ sm: "22px", lg: "0px" }}
                >
                  <Flex align="center">
                    <IconBox h={"45px"} w={"45px"} bg={iconBlue} me="16px">
                      <HomeIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                    </IconBox>
                    <Flex direction="column">
                      <Text color="gray.400" fontWeight="normal" fontSize="md">
                        Grupos de keywords
                      </Text>
                      <Text color={textColor} fontWeight="bold" fontSize="xl">
                        {keywordSetNumber}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex direction="column" alignSelf="flex-end" minW="125px">
                    <Text color="gray.400" fontWeight="normal" fontSize="md">
                      60%
                    </Text>
                    <Progress
                      colorScheme="blue"
                      size="sm"
                      borderRadius="15px"
                      value={60}
                    />
                  </Flex>
                </Flex>
                <LineChart
                  chartData={lineChartDataGeneral}
                  chartOptions={lineChartOptionsGeneral}
                />
              </Flex>
            </CardHeader>
          </Card>
          <Card maxW={{ sm: "330px", md: "100%" }}>
            <Flex justify="space-between" w="100%" minH="190px">
              <Flex direction="column">
                <Flex align="center">
                  <IconBox h={"45px"} w={"45px"} bg={iconBlue} me="16px">
                    <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                  </IconBox>
                  <Flex direction="column">
                    <Text color="gray.400" fontWeight="normal" fontSize="md">
                      Projects
                    </Text>
                    <Text color={textColor} fontWeight="bold" fontSize="xl">
                      115
                    </Text>
                  </Flex>
                </Flex>
                <Flex direction="column" mt="36px">
                  <Flex align="center">
                    <Icon
                      as={BsCircleFill}
                      color="blue.500"
                      w="10px"
                      h="10px"
                      me="8px"
                    />
                    <Text color={textColor} fontWeight="normal" fontSize="md">
                      Done
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      as={BsCircleFill}
                      color="gray.400"
                      w="10px"
                      h="10px"
                      me="8px"
                    />
                    <Text color={textColor} fontWeight="normal" fontSize="md">
                      In progress
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <DonutChart
                chartData={donutChartDataGeneral}
                chartOptions={donutChartOptionsGeneral}
              />
            </Flex>
          </Card>
        </Flex>
        {*/}
      </Grid>
    </Flex>
  );
}

export default CampaignDashboard;
