import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import SearchTable3 from "components/Tables/SearchTable3";
import ModalSearchTable from "components/Tables/ModalSearchTable";
import axiosClient from "../../axiosClient";

const CampaignKeywordsModal = ({
  tableData,
  isOpen,
  onClose,
  setId,
  nombreSet,
}) => {
  const [tableData3, setTableData3] = useState(tableData);
  const columnsData = [
    {
      Header: "ID",
      accessor: "id",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "QUERY",
      accessor: "query",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "CLICKS",
      accessor: "clicks",
      minWidth: "10px",
      width: "15px",
    },
    {
      Header: "IMPR",
      accessor: "impressions",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "CTR",
      accessor: "ctr",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "POSITION",
      accessor: "position",
      minWidth: "15px",
      width: "50px",
    },
    {
      Header: "IMP",
      accessor: "importance",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "DATE",
      accessor: "date",
      minWidth: "50px",
      width: "100px",
    },
  ]; // Add your columns data here

  const resetSets = (keywordset) => {
    onClose(keywordset);
    //  console.log(keywordset);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modal Title</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ModalSearchTable
            tableData={tableData3}
            columnsData={columnsData}
            onSave={resetSets}
            setId={setId}
            nombreSet={nombreSet}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CampaignKeywordsModal;
