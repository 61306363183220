import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Spinner,
  IconButton,
  Modal,
  ModalOverlay,
  Icon,
  ModalContent,
  HStack,
  ModalHeader,
  ModalBody,
  VStack,
  ModalCloseButton,
  Link,
  Button,
  useToast,
  Text,
  Heading,
} from "@chakra-ui/react";
import {
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaWordpress,
  FaTrash,
} from "react-icons/fa";
import { FaPlane } from "react-icons/fa";
import { SelectBar } from "components/Navbars/SelectBar/SelectBar";
import BlogPostSettings from "components/Custom/BlogPostSettings";
import axiosClient from "../../axiosClient.js";

const SocialNetworkPosting = ({ keywordsetId, onUpdate }) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [noContent, setNoContent] = useState(false);
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(false);
  const [hayContenido, setHayContenido] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    facebook: false,
    wordpress: false,
  });

  const [formData, setFormData] = useState({
    parrafos: "",
    palabras: "",
    subtitulos: "",
    tablaDeContenidos: "",
  });

  const handlePublicar = () => {
    axiosClient
      .post(
        "https://www.swiftiesbrasil.com/wp-json/pseo-tech/v1/crear_entrada_2/",
        {
          title: "Titulo de la entrada",
          content: content,
          excerpt: "Excerpt de la entrada",
          status: "publish",
        }
      )
      .then((response) => {
        // console.log(response);
        toast({
          title: "Publicado!",
          description: "La entrada ha sido publicada en el blog.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Error al publicar",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleFormChange = (inputName, inputValue) => {
    setFormData((prev) => ({ ...prev, [inputName]: inputValue }));
  };

  //aca genero para wp
  const [copyWpContent, setCopyWordpresContent] = useState();
  const [parsedContent, setParsedContent] = useState();
  const handleSubmit = () => {
    setLoading(true);
    //console.log("Enviando datos al API:", formData);
    const title4WP = localStorage.getItem("titulo|" + keywordsetId);
    axiosClient
      .post(
        `${process.env.REACT_APP_API_URL}/content/keywordset/${keywordsetId}/wordpress`,
        {
          ...formData,
          landing:
            selectedValue !== ""
              ? lasLandings.filter((item) => item.id === selectedValue)[0].name
              : null,
          title: title4WP !== null ? title4WP : "",
        }
      )
      .then((response) => {
        setWordpressContentId(response.data.contentId);
        setCopyWordpresContent(response.data.result);
        console.log("la response", response);
        // console.log("sin tocar", response.data);
        const result = response.data.result;
        const content = parseContent(result);
        setParsedContent(content);
        // console.log("Contenido generado:", content);
        onUpdate();
        setNoContent(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
      });
  };

  const handleDeleteContent = (id) => {
    axiosClient
      .delete(`${process.env.REACT_APP_API_URL}/content/${id}`)
      .then((response) => {
        toast({
          title: "Eliminado!",
          description: "Se ha eliminado el contenido.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onUpdate();
        handleCloseModal();
      })
      .catch((error) => {
        toast({
          title: "Error!",
          description: "Ha ocurrido un error al eliminar el contenido.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const parseContent = (content) => {
    console.log("el content", content);
    const regex = /<(Text|Heading|h1|h2|p|a)[^>]*>(.*?)<\/\1>/g;
    const matches = content.matchAll(regex);
    const parsedContent = [];
    for (const match of matches) {
      const tag = match[1];
      const text = match[2];
      let element;
      switch (tag) {
        case "Heading":
          element = <Heading margin="15px 0">{text}</Heading>;
          break;
        case "h1":
          element = <Heading margin="15px 0">{text}</Heading>;
          break;
        case "Text":
          element = (
            <Text fontWeight="bold" margin="15px 0">
              {text}
            </Text>
          );
          break;
        case "h2":
          element = (
            <Text fontWeight="bold" margin="15px 0">
              {text}
            </Text>
          );
          break;
        case "p":
          element = <p>{text}</p>;
          break;
        // Add more cases for other tags if needed
        case "a":
          element = <Link href="#">{text}</Link>;
          break;
        default:
          element = null;
          break;
      }

      if (element) {
        parsedContent.push(element);
      }
    }

    return parsedContent;
  };

  const [wordpressContentId, setWordpressContentId] = useState();
  const verificarContenidoInicial = () => {
    const redes = ["twitter", "linkedin", "instagram", "facebook", "wordpress"];
    redes.forEach((network) => {
      axiosClient
        .get(
          `${process.env.REACT_APP_API_URL}/content/keywordset/${keywordsetId}/${network}`
        )
        .then((response) => {
          if (network === "wordpress") {
            setWordpressContentId(response.data._id);
          }
          const result = response.data;
          setHayContenido((prevState) => ({
            ...prevState,
            [network]: !!result,
          }));
        })
        .catch((error) => {
          console.error(
            `Error fetching initial content for ${network}: ${error}`
          );
        });
    });
  };

  const [lasLandings, setLasLandings] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const fetchCampaignData = async (id) => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_URL}/campaign/${id}`
      );
      const tmp = response.data.landingId;
      setLasLandings(
        tmp.map((item) => ({
          id: item._id,
          name: item.url,
        }))
      );

      //  console.log("las landings?", tmp);
    } catch (error) {
      console.error("Error fetching campaign data:", error);
    }
  };

  const [noLandings, setNoLandings] = useState(false);
  useEffect(() => {
    if (localStorage.campaignId) {
      setNoLandings(false);
      fetchCampaignData(localStorage.campaignId);
    } else {
      setNoLandings(true);
    }
    verificarContenidoInicial();
  }, []);
  const [aEliminar, setAeliminar] = useState();
  const handleIconClick = (network) => {
    setLoading(true);
    const networkLowerCase = network.toLowerCase();
    setSelectedNetwork(networkLowerCase);
    axiosClient
      .get(
        `${process.env.REACT_APP_API_URL}/content/keywordset/${keywordsetId}/${networkLowerCase}`
      )
      .then((response) => {
        const result = response.data;
        setNoContent(!result);
        if (networkLowerCase === "wordpress" && result) {
          const temp = parseContent(result.text);
          setParsedContent(temp);
          setCopyWordpresContent(result.text);
        } else {
          setContent(result ? result.text : "");
          setAeliminar(result._id);
        }

        setHayContenido((prevState) => ({
          ...prevState,
          [networkLowerCase]: !!result,
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Error fetching content",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      });
    setIsOpen(true);
  };
  const handleGenerarContent = (network) => {
    setLoading(true);
    const titleToUse = localStorage.getItem("titulo|" + keywordsetId);

    axiosClient
      .post(
        `${process.env.REACT_APP_API_URL}/content/keywordset/${keywordsetId}/${network}`,
        {
          landing:
            selectedValue !== ""
              ? lasLandings.filter((item) => item.id === selectedValue)[0].name
              : null,
          title: titleToUse !== null ? titleToUse : "",
        }
      )
      .then((response) => {
        const result = response.data.result;
        if (network === "wordpress") {
          setWordpressContentId(response.data.contentId);
        }
        setContent(result);
        setNoContent(false);
        onUpdate();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Error fetching content",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    verificarContenidoInicial();
    setContent("");
    setSelectedNetwork("");
    setNoContent(true);
    setIsOpen(false);
  };

  return (
    <>
      <SimpleGrid columns={3} spacing={4}>
        <IconButton
          icon={<FaTwitter />}
          colorScheme={hayContenido.twitter ? "twitter" : "gray"}
          onClick={() => handleIconClick("Twitter")}
        />
        <IconButton
          icon={<FaLinkedin />}
          colorScheme={hayContenido.linkedin ? "linkedin" : "gray"}
          onClick={() => handleIconClick("LinkedIn")}
        />
        <IconButton
          icon={<FaInstagram />}
          color={hayContenido.instagram ? "white" : "gray"}
          backgroundColor={hayContenido.instagram ? "#f43637" : ""}
          onClick={() => handleIconClick("Instagram")}
        />
        <IconButton
          icon={<FaFacebook />}
          colorScheme={hayContenido.facebook ? "facebook" : "gray"}
          onClick={() => handleIconClick("Facebook")}
        />
        <IconButton
          icon={<FaWordpress />}
          color={hayContenido.wordpress ? "white" : "gray"}
          backgroundColor={hayContenido.wordpress ? "black" : ""}
          onClick={() => handleIconClick("Wordpress")}
        />
      </SimpleGrid>

      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        size={selectedNetwork !== "wordpress" ? "lg" : "full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedNetwork ? selectedNetwork : <Spinner />}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={9}>
            {!noContent ? (
              <>
                <Box>
                  {selectedNetwork !== "wordpress" ? (
                    <VStack>
                      <Text>{content}</Text>
                      <Button
                        onClick={() => {
                          handleDeleteContent(aEliminar);
                        }}
                      >
                        Eliminar
                      </Button>
                    </VStack>
                  ) : (
                    parsedContent && (
                      <div>
                        <Box>
                          <Button
                            onClick={() => {
                              handleDeleteContent(wordpressContentId);
                            }}
                          >
                            Eliminar
                          </Button>
                        </Box>

                        <div>
                          {parsedContent.map((element, index) => (
                            <React.Fragment key={index}>
                              {element}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </Box>
                <Box>
                  {selectedNetwork !== "wordpress" ? (
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(content);
                        toast({
                          title: "Copiado!",
                          description:
                            "Tienes el contenido en el portapapeles.",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                      }}
                    >
                      Copiar
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(copyWpContent);
                          toast({
                            title: "Copiado!",
                            description:
                              "Tienes el contenido en el portapapeles.",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        }}
                      >
                        Copiar
                      </Button>
                      <Button onClick={() => handlePublicar()}>Publicar</Button>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <>
                <p>No hay contenido, generar?</p>
                {!noLandings ? (
                  <HStack>
                    <small>landing a linkear:</small>
                    <Icon as={FaPlane} color={"black"} />
                    <SelectBar
                      options={lasLandings}
                      onChange={(e) => setSelectedValue(e.target.value)}
                    />
                  </HStack>
                ) : (
                  <small>
                    Atencion: No has ingresado landings a la campaña!
                  </small>
                )}
                {selectedNetwork === "wordpress" ? (
                  <BlogPostSettings
                    onChange={handleFormChange}
                    onSubmit={handleSubmit}
                    loading={loading}
                  />
                ) : (
                  <Button
                    isLoading={loading}
                    onClick={() => handleGenerarContent(selectedNetwork)}
                  >
                    Si!
                  </Button>
                )}
                <Button isLoading={loading} onClick={() => handleCloseModal()}>
                  Cancelar
                </Button>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SocialNetworkPosting;
