// axiosClient.js
import axios from "axios";

// Crear una instancia de axios con configuraciones por defecto
const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`, // Tu URL base de la API
  // Puedes añadir más configuraciones predeterminadas aquí
});

// Función para obtener el token de la cookie
const getTokenFromCookie = () => {
  if (document.cookie) {
    const tokenFromCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];
    return tokenFromCookie || null;
  }
  return null;
};

// Agregar un interceptor de solicitud para incluir el token en cada solicitud
axiosClient.interceptors.request.use(
  (config) => {
    const token = getTokenFromCookie(); // Obtener el token de la cookie
    if (token) {
      // console.log("Adding token to the request header");
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
