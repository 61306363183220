import React, { useEffect } from "react";
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/provider";
import ReactDOM from "react-dom";
import theme from "theme/theme.js";
import CustomDashboard from "views/Pages/Projects/Dashboard.js";
import NewCampaign from "views/Pages/Campaigns/NewCampaign.js";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import LandingLayout from "layouts/Landing";
import { jwtDecode } from "jwt-decode";
import MobileWarning from "components/Custom/MobileWarning";
import OAuthRedirectPage from "views/Pages/Profile/OAuthRedirect";
import TermsOfService from "views/Pages/TermsOfService";
import PrivacyPolicy from "views/Pages/PrivacyPolicy";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
function App() {
  const history = useHistory();

  useEffect(() => {
    const checkAuth = () => {
      const exemptPaths = ["/privacy_policy", "/terms_of_service"];
      if (exemptPaths.includes(location.pathname)) {
        return;
      }

      const token = document.cookie
        .split("; ")
        .find((row) => row.startsWith("token="))
        ?.split("=")[1];

      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 < new Date().getTime()) {
            history.push("/auth");
          }
          localStorage.setItem("userId", decodedToken.sub);
        } catch (error) {
          console.error("Token decoding failed:", error);
          history.push("/auth");
        }
      } else {
        history.push("/auth");
      }
    };

    checkAuth();
  }, [history]);

  return (
    <Switch>
      <Route path={"/privacy_policy"} component={PrivacyPolicy} />
      <Route path={"/terms_of_service"} component={TermsOfService} />
      <Route path={"/oauth-redirect"} component={OAuthRedirectPage} />
      <Route path={`/auth`} component={AuthLayout} />
      <Route path={`/admin`} component={AdminLayout} />
      <Route path={`/landing`} component={LandingLayout} />
      <Route path={`/rtl`} component={RTLLayout} />
      <Route
        path="/admin/pages/projects/dashboard"
        component={CustomDashboard}
      />
      <Route
        exact
        path="/admin/pages/campaign/new-campaign/:id"
        component={NewCampaign}
      />
      <Redirect from={`/`} to="/auth/authentication/sign-in/cover" />

      {/*}
      <Redirect from={`/test`} to="/admin/dashboard" />
      {*/}
    </Switch>
  );
}

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <MobileWarning />
      <App />
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById("root")
);
