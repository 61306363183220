import React, { useState, useEffect } from "react";
import {
  Flex,
  Checkbox,
  Text,
  Icon,
  IconButton,
  Stack,
  Spinner,
  SimpleGrid,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/Separator/Separator";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoLogoWordpress } from "react-icons/io";
import AcordionWrapper from "./AcordionWrapper";
import axiosClient from "../../axiosClient";
import { Link } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { useHistory } from "react-router-dom";

const ProjectsComponent = ({ id }) => {
  const [projects, setProjects] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const history = useHistory();
  useEffect(() => {
    // console.log(id);
    const fetchProjects = async () => {
      try {
        const response = await axiosClient.get(
          `${process.env.REACT_APP_API_URL}/projects/findByCreatedBy/${id}`
        );
        //console.log(response.data);
        setProjects(response.data);
        // console.log(response.data[0].campaigns);
        //setCampaigns(response.data.campaigns);
      } catch (error) {
        console.error("There was an error fetching the projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const addCampaign = (projectId) => {
    history.push(`/admin/pages/campaign/new-campaign/${projectId}`);
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteProject = async (id) => {
    const confirmDelete = window.confirm("¿Deseas eliminar el proyecto?");
    if (confirmDelete) {
      setIsDeleting(true);
      try {
        await axiosClient
          .delete(`${process.env.REACT_APP_API_URL}/projects/${id}`)
          .then((response) => {
            // console.log(response);
            if (response.status === 200) {
              setProjects(projects.filter((project) => project._id !== id));
            }
          });
      } catch (error) {
        console.error("There was an error deleting the project:", error);
      } finally {
        setIsDeleting(false); // Desactivar el spinner después de la solicitud
      }
    }
  };

  return (
    <div>
      {projects.length !== 0 ? (
        projects.map((project) => (
          <div key={project._id}>
            <AcordionWrapper titulo={project.name}>
              <Flex key={project._id} direction="column" mb="14px">
                {/* Project Header */}
                <Flex align="right" justify="flex-end">
                  <IconButton
                    icon={
                      isDeleting ? <Spinner size="sm" /> : <RiDeleteBin6Fill />
                    }
                    isDisabled={isDeleting}
                    size="md"
                    ml="3px"
                    p="5px"
                    bg={project.color}
                    color="white"
                    onClick={() => deleteProject(project._id)}
                  />

                  <IconButton
                    as={MdCampaign}
                    size="md"
                    ml="3px"
                    p="5px"
                    bg={project.color}
                    color="white"
                    onClick={() => addCampaign(project._id)}
                  />
                </Flex>
                {/* Website Details */}
                {project.websites.map((website) => (
                  <Flex
                    key={website._id}
                    direction="column"
                    p="12px 22px"
                    w="100%"
                    borderLeft="3px solid blue.500"
                  >
                    <Text
                      color={textColor}
                      fontSize="md"
                      fontWeight="bold"
                      mb="4px"
                    >
                      {website.name}
                    </Text>
                    <Stack direction="row" spacing="20px" align="center">
                      <Text color="gray.400" fontWeight="normal" fontSize="md">
                        URL: <a href={website.url}>{website.url}</a>
                      </Text>
                      <Text color="gray.500" fontWeight="bold" fontSize="md">
                        Created At: {formatDate(website.createdAt)}
                      </Text>
                    </Stack>
                    <Text color="gray.500" fontSize="md" mb="4px">
                      {website.description}
                    </Text>
                    <Flex align="center" justify="flex-end">
                      {website.pluginInstall ? (
                        <Icon color="green" as={IoLogoWordpress} />
                      ) : (
                        <Icon color="red.200" as={IoLogoWordpress} />
                      )}
                    </Flex>
                  </Flex>
                ))}
                {/* Campaign Details */}

                <SimpleGrid columns={2} spacing={4}>
                  {project.campaigns.length !== 0
                    ? project.campaigns.map((campaign) => (
                        <Card key={campaign._id}>
                          <CardHeader py={3}>
                            <Text
                              color={textColor}
                              fontSize="md"
                              fontWeight="bold"
                            >
                              Campaign {campaign.nombre}
                            </Text>
                          </CardHeader>
                          <CardBody>
                            <Stack
                              direction="row"
                              spacing="20px"
                              align="center"
                            >
                              <Text
                                color="gray.400"
                                fontWeight="normal"
                                fontSize="sm"
                              >
                                Objetivo: {campaign.objetivo}
                              </Text>
                              <Text
                                color="gray.500"
                                fontWeight="bold"
                                fontSize="sm"
                              >
                                Created At: {formatDate(campaign.createdAt)}
                              </Text>
                            </Stack>
                            <Text color="gray.500" fontSize="sm" mb="4px">
                              {campaign.description}
                            </Text>
                            <Flex align="center" justify="flex-end">
                              <IconButton
                                icon={<IoEllipsisHorizontalSharp />}
                                size="sm"
                                ml="auto"
                              />
                            </Flex>
                          </CardBody>
                        </Card>
                      ))
                    : "No campaigns found"}
                </SimpleGrid>
              </Flex>
            </AcordionWrapper>
            <HSeparator my="14px" />
          </div>
        ))
      ) : (
        <Box>
          <Text>No projects found</Text>
          <Link to="/admin/pages/projects/new-project">
            <IconButton
              as={IoAdd}
              ml="auto"
              size="xs"
              colorScheme="blue"
              aria-label="Add Project"
            />
          </Link>
        </Box>
      )}
    </div>
  );
};

export default ProjectsComponent;
