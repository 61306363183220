import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

const PrivacyPolicy = () => (
  <Box p={6}>
    <Heading as="h1" mb={4}>
      Política de Privacidad
    </Heading>
    <Text mb={2}>
      <strong>Fecha de vigencia: 01/01/2023</strong>
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      1. Información que Recopilamos
    </Heading>
    <Text>
      No recopilamos información personal identificable de nuestros usuarios.
      Solo recopilamos datos relacionados con las palabras clave y sitios web
      proporcionados por los usuarios para la prestación de nuestro Servicio.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      2. Uso de la Información
    </Heading>
    <Text>
      Utilizamos los datos proporcionados por los usuarios exclusivamente para
      generar contenido escrito y mejorar el posicionamiento en Google. No
      compartimos, vendemos ni divulgamos esta información a terceros.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      3. Seguridad
    </Heading>
    <Text>
      Implementamos medidas de seguridad razonables para proteger los datos de
      nuestros usuarios contra el acceso no autorizado, la divulgación o la
      destrucción.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      4. Cookies
    </Heading>
    <Text>
      Nuestro Sitio puede utilizar cookies para mejorar la experiencia del
      usuario. Las cookies son pequeños archivos de texto que se almacenan en tu
      dispositivo para recordar tus preferencias y visitas anteriores.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      5. Enlaces a Otros Sitios Web
    </Heading>
    <Text>
      Nuestro Sitio puede contener enlaces a otros sitios web que no están
      operados por nosotros. No somos responsables por las prácticas de
      privacidad de estos sitios externos.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      6. Cambios en la Política de Privacidad
    </Heading>
    <Text>
      Nos reservamos el derecho de modificar esta Política de Privacidad en
      cualquier momento. Las modificaciones serán efectivas desde el momento en
      que se publiquen en el Sitio. Es responsabilidad del usuario revisar
      periódicamente esta Política de Privacidad para estar al tanto de
      cualquier cambio.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      7. Contacto
    </Heading>
    <Text>
      Si tienes alguna pregunta sobre esta Política de Privacidad, por favor
      contáctanos en{" "}
      <a href="mailto:contacto@app.pseo.tech">contacto@app.pseo.tech</a>.
    </Text>
  </Box>
);

export default PrivacyPolicy;
