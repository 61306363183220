import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Stack,
  Select,
  VStack,
  IconButton,
  useToast,
  Container,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import axiosClient from "../../../axiosClient.js";

function NewCampaignForm() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState({
    nombre: "",
    objetivo: "",
    projectId: localStorage.projectId || id,
    userId: localStorage.userId,
  });
  const textColor = useColorModeValue("gray.700", "white");

  const toast = useToast();

  const handleCampaignNameChange = (e) => {
    setCampaign({
      ...campaign,
      nombre: e.target.value,
    });
  };
  const handleCampaignObjChange = (e) => {
    setCampaign({
      ...campaign,
      objetivo: e.target.value,
    });
  };

  const [projectsData, setProjectsData] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (id === ":id") {
      if (localStorage.getItem("projectId")) {
        setSelectedProject(localStorage.getItem("projectId"));
      }
      axiosClient
        .get(
          `${process.env.REACT_APP_API_URL}/projects/findByCreatedBy/${userId}`
        )
        .then((response) => {
          const projects = response.data.map((project) => ({
            id: project._id,
            name: project.name,
          }));
          setProjectsData(projects);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const handleSelectProject = (e) => {
    const selectedId = e.target.value;
    setSelectedProject(selectedId);
    setCampaign({ ...campaign, projectId: selectedId });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await axiosClient.post(
        process.env.REACT_APP_API_URL + "/campaign/create",
        campaign
      );
      toast({
        title: campaign.nombre + " saved successfully.",
        description: "We've saved your campaign data.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Error saving " + campaign.nombre,
        description: "There was an error saving your campaign.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxW="container.md"
      marginTop="135px"
      background="white"
      borderRadius="12px"
      paddingTop="21px"
    >
      <FormControl id="campaign-name">
        <FormLabel>Campaign Name</FormLabel>
        <Input
          value={campaign.nombre}
          onChange={handleCampaignNameChange}
          placeholder="Enter Campaign name"
        />
      </FormControl>
      <FormControl id="campaign-objetivo">
        <FormLabel>Objetivo</FormLabel>
        <Input
          value={campaign.objetivo}
          onChange={handleCampaignObjChange}
          placeholder="Enter Campaign objective"
        />
      </FormControl>
      {id === ":id" && (
        <FormControl>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Project
          </FormLabel>
          <Select
            onChange={handleSelectProject}
            value={selectedProject} // Use `value` to control the Select component
          >
            {projectsData.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
      <Button
        mt={4}
        ml={3}
        colorScheme="green"
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        Save Campaign
      </Button>
    </Container>
  );
}

export default NewCampaignForm;
