import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Avatar,
  Badge,
  Button,
  Box,
  Checkbox,
  Flex,
  Grid,
  Icon,
  Progress,
  Stack,
  HStack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar4 from "assets/img/avatars/avatar4.png";
import basicDark from "assets/img/BgMusicCard.png";
import basicBlue from "assets/img/basic-auth.png";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  HomeIcon,
  WalletIcon,
} from "components/Icons/Icons";
// Custom components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import DonutChart from "components/Charts/DonutChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { HSeparator } from "components/Separator/Separator";
import {
  donutChartDataGeneral,
  donutChartOptionsGeneral,
  lineChartDataGeneral,
  lineChartOptionsGeneral,
} from "variables/charts";
import { jwtDecode } from "jwt-decode";
import axiosClient from "axiosClient";
//import CampaignComponent from "components/Custom/CampaignComponent";
//import CampaignCard from "components/Custom/CampaignCard";

import SearchTable2 from "components/Tables/SearchTable2";

function KeywordsDashboard() {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const iconBoxInside = useColorModeValue("white", "white");
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const bgSalaryCard = useColorModeValue("gray.50", "navy.900");
  const bgButton = useColorModeValue("gray.700", "blue.500");
  const [username, setUsername] = useState("");
  const [ID, setID] = useState("");
  const [token, setToken] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [tableData2, setTabledata2] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");

  const columnsData2 = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "QUERY",
      accessor: "query",
    },
    {
      Header: "CLICKS",
      accessor: "clicks",
    },
    {
      Header: "IMPR",
      accessor: "impressions",
    },
    {
      Header: "CTR",
      accessor: "ctr",
    },
    {
      Header: "POSITION",
      accessor: "position",
    },
    {
      Header: "IMP",
      accessor: "importance",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];

  const handleSubmit = () => {
    setIsLoading(true);
    fetchData(selectedProject);
    setIsLoading(false);
  };

  const handleSelectProject = (e) => {
    setSelectedProject(e.target.value);
    fetchData(e.target.value);
  };

  const [projectId, setProjectId] = useState("");

  // Función para comparar fechas (año, mes, día)
  function compararFechas(fecha) {
    // Crear un objeto Date para la fecha actual
    const ayer = new Date();

    // Restar un día
    ayer.setDate(ayer.getDate() - 8);

    // Redondear la fecha de hoy a medianoche para eliminar horas, minutos y segundos
    ayer.setHours(0, 0, 0, 0);

    // Crear un objeto Date para la fecha a comparar
    const fechaComparar = new Date(fecha);
    fechaComparar.setHours(0, 0, 0, 0);
    if (fechaComparar > ayer) {
      //   console.log("hoy: " + ayer);
      // console.log("fechaComparar: " + fechaComparar);
    }
    // Comparar ambas fechas
    return fechaComparar > ayer;
  }

  const fetchData = async (id) => {
    try {
      setTabledata2([]);
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_URL}/keyword/from-project/${id}`
      );
      //  console.log(response.data);
      // Mapear la respuesta a un nuevo formato
      const mappedData = response.data.map((item) => {
        // Calcula la importancia como mencionaste
        const importance = item.impressions * item.position * (item.clicks + 1);
        console.log(item);
        // Extrae la fecha de la propiedad 'query'
        var date = item.query.match(/\d{4}-\d{2}-\d{2}$/)[0];
        return {
          id: item._id,
          query: item.query.replace(date, "").trim(), // Remover la fecha del query y trim espacios extra
          clicks: item.clicks,
          impressions: item.impressions,
          ctr: item.ctr,
          position: item.position,
          importance: importance,
          date: date,
        };
      });
      //  console.log(mappedData.filter(Boolean));
      setTabledata2(mappedData.filter(Boolean));
      setProjectId(id);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjects = async () => {
    try {
      const userId = localStorage.getItem("userId");
      axiosClient
        .get(
          `${process.env.REACT_APP_API_URL}/projects/findByCreatedBy/${userId}`
        )
        .then((response) => {
          const projects = response.data.map((project) => ({
            id: project._id,
            name: project.name,
          }));
          setProjectsData(projects);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      // Desde la cookie
      if (document.cookie !== undefined && document.cookie !== "") {
        const tokenFromCookie = document.cookie
          .split("; ")
          .find((row) => row.startsWith("token="))
          .split("=")[1];
        const decodedToken = jwtDecode(tokenFromCookie);
        if (decodedToken.exp < new Date().getTime() / 1000) {
          history.push("/auth");
        }
        //  console.log(decodedToken);
        localStorage.setItem("userId", decodedToken.sub);
        setID(decodedToken.sub);
        setUsername(decodedToken.username);
        setToken(tokenFromCookie);
        if (localStorage.getItem("projectId")) {
          setSelectedProject(localStorage.getItem("projectId"));
          fetchData(selectedProject);
        }
        fetchProjects();
      }
    } catch (error) {
      console.log(error);
    }
  }, [ID, token]);

  function Fecha() {
    const today = new Date();
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = today.toLocaleDateString("es-ES", options);
    return formattedDate;
  }
  // Desde localStorage

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Grid
        templateColumns={{
          sm: "1fr",
          lg: "0.5fr 1.5fr 1fr",
          xl: "1fr 1.7fr 1.5fr",
        }}
        gap="24px"
        mb="24px"
      >
        <Card>
          <CardHeader mb="28px">
            <Flex>
              <Avatar
                src={
                  "https://api.dicebear.com/8.x/bottts/svg?seed=" +
                  localStorage.userId
                }
                w="40px"
                h="40px"
                mr="15px"
                background="transparent"
              />
              <Flex direction="column">
                <Text color={textColor} fontSize="md" fontWeight="bold">
                  {username}
                </Text>
                <Text color="gray.400" fontSize="sm" fontWeight="normal">
                  2h ago
                </Text>
              </Flex>
            </Flex>
          </CardHeader>

          <Flex direction="column" w="100%">
            <Flex direction="column" maxW="400px" mb="32px">
              <Text color={textColor} fontSize="lg" fontWeight="bold" mb="4px">
                CTO.
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Cheef Technology Officer
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1fr 1fr", xl: "1fr 1fr 1fr" }}
        gap="24px"
        mb="24px"
      >
        <FormControl>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            <Select
              onChange={(e) => handleSelectProject(e)}
              value={selectedProject}
            >
              <option key={"0"} value={0}>
                Select a project
              </option>
              {projectsData.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Select>
          </FormLabel>
        </FormControl>
        <FormControl>
          {/*}
          <Button
            mt={4}
            ml={3}
            colorScheme="green"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Cargar Keywords
          </Button>
            {*/}
        </FormControl>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1fr 1fr", xl: "1fr 1fr 1fr" }}
        gap="24px"
      >
        <Card p={{ sm: "16px", md: "22px" }} maxW={{ sm: "330px", md: "100%" }}>
          <CardHeader>
            <Flex justify="space-between" w="100%">
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                Tus Keywords
              </Text>

              <Text color="black.400" fontSize="md" fontWeight="normal">
                <HStack spacing="10px">
                  <Text
                    color="gray.400"
                    style={{ fontAlign: "right" }}
                    fontSize="md"
                    fontWeight="normal"
                  >
                    Fecha de hoy
                  </Text>
                  <Box as="span">{Fecha()}</Box>
                </HStack>
              </Text>
            </Flex>
          </CardHeader>

          <Flex
            direction="column"
            w="100%"
            overflowX={{ sm: "scroll", md: "hidden" }}
          >
            <Card px="0px">
              <CardBody>
                {tableData2.length > 0 && projectId !== "" && (
                  <SearchTable2
                    tableData={tableData2}
                    columnsData={columnsData2}
                    projectId={projectId}
                  />
                )}
              </CardBody>
            </Card>
          </Flex>
        </Card>
      </Grid>
    </Flex>
  );
}

export default KeywordsDashboard;
