import React, { useState, useEffect } from "react";
import { FaPlane } from "react-icons/fa";
import { MdArrowForwardIos } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Tag,
  useToast,
  TagCloseButton,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Select,
  Divider,
  Heading,
  Text,
  Button,
  Icon,
  IconButton,
  Checkbox,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaWordpress,
  FaTrash,
} from "react-icons/fa";
import { SelectBar } from "components/Navbars/SelectBar/SelectBar";
import CampaignKeywordsModal from "components/Custom/CampaignKeywordsModal";
import SocialNetworkPosting from "components/Custom/SocialNetworkPosting";
import KeywordTags from "components/Custom/KeywordTags";
import BlogPostSettings from "components/Custom/BlogPostSettings";
import Puntitos from "components/Custom/Puntitos";
import axiosClient from "../../axiosClient.js";
import ReferenciasForm from "components/Custom/ReferenciasForm";

const EditSetsAccordionItem = ({ set, lasLandings, tableData, onUpdated }) => {
  const toast = useToast();
  const [selectedValue, setSelectedValue] = useState("");
  const [elSet, setElSet] = useState(set);
  const [investigateResult, setInvestigateResult] = useState({});
  const [loadingInvestigation, setLoadingInvestigation] = useState(false);
  const [hayContenido, setHayContenido] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    facebook: false,
    wordpress: false,
  });
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [cargandoSocialPosts, setCargandoSocialPosts] = useState(false);
  const [researchPoints, setResearchPoints] = useState([]);

  const [selectedNumber, setSelectedNumber] = useState(1);

  const [procesando, setProcesando] = useState(false);

  const [losH2, setLosH2] = useState("");
  const generarH2 = async () => {
    setProcesando(true);
    const amapear = elSet.referenceUrls.length;

    const filtrados = Array.from({ length: amapear }, (_, index) => {
      const storedState = localStorage.getItem(
        "checkboxListState-" + elSet._id + "-" + index
      );
      if (storedState) {
        const parsedState = JSON.parse(storedState);
        return parsedState.filter((point) => point.isChecked);
      }
      return [];
    }).flat();
    console.log(filtrados);
    const putin = filtrados.map((item) => item.point).join(", ");
    console.log(putin);
    console.log(selectedNumber);
    const elcontent = localStorage.getItem("selectedPoints") + ", " + putin;
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/research/generar-h2/`,
        { cantidad: selectedNumber, contenido: elcontent }
      );
      console.log(response.data.choices[0].message.content);
      const eljson = parseAndReplacePoints(
        response.data.choices[0].message.content
      );
      setH2ses(eljson);
      console.log(h2ses, eljson);
      setProcesando(false);
    } catch (error) {
      console.error("Error generating H2: ", error);
      setProcesando(false);
    }
  };

  const RenderHeadings = ({ titles }) => {
    return (
      <div>
        {Object.keys(titles).map((key, index) => (
          <h2 key={index}>{titles[key]}</h2>
        ))}
      </div>
    );
  };

  const verificarContenidoInicial = () => {
    setCargandoSocialPosts(true);
    const redes = ["twitter", "linkedin", "instagram", "facebook", "wordpress"];
    const requests = redes.map((network) => {
      return axiosClient
        .get(
          `${process.env.REACT_APP_API_URL}/content/keywordset/${set._id}/${network}`
        )
        .then((response) => {
          return { network, result: response.data };
        })
        .catch((error) => {
          console.error(
            `Error fetching initial content for ${network}: ${error}`
          );
          return { network, result: null }; // handle error case by returning null result
        });
    });

    Promise.all(requests).then((results) => {
      const newHayContenido = results.reduce(
        (prevState, { network, result }) => ({
          ...prevState,
          [network]: !!result,
        }),
        {}
      );
      setHayContenido(newHayContenido);
      setCargandoSocialPosts(false);
    });
  };

  useEffect(() => {
    verificarContenidoInicial();
    loadResearch(set._id);
    console.log(elSet.referenceUrls.length);
  }, []);

  const loadResearch = async (id) => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_URL}/keywordset/${id}`
      );
      setElSet((currentElSet) => ({
        ...currentElSet,
        research: response.data.research,
      }));
      parseResearchPoints(response.data.research);
    } catch (error) {
      console.error("Error loading research: ", error);
    }
  };

  const parseResearchPoints = (research) => {
    if (!research) return;
    const points = research
      .match(/<li[^>]*>(.*?)<\/li>/g)
      .map((point) => point.replace(/<\/?li>/g, ""));
    setResearchPoints(points);
  };

  const handleUpdateSocialContent = (network) => {
    setCargandoSocialPosts(true);
    verificarContenidoInicial();
  };

  const removeKeyword = async (id) => {
    let newKeywordSet;
    try {
      const respuesta = await axiosClient.patch(
        `${process.env.REACT_APP_API_URL}/keywordset/removeone/${elSet._id}/${id}`
      );

      toast({
        title: respuesta.data.name + " updated successfully.",
        description: "We've updated your campaign data.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setElSet((currentElSet) => ({
        ...currentElSet,
        keywordId: currentElSet.keywordId.filter(
          (keyword) => keyword._id !== id
        ),
      }));
    } catch (error) {
      toast({
        title: "Error saving " + error.message,
        description: "There was an error saving your campaign.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const [aceptado, setAceptado] = useState(false);
  const handleAceptar = () => {
    setAceptado(true);
  };

  const closeModal = (set) => {
    setModalOpen(false);
    if (set !== undefined) setElSet(set);
  };

  const [borrando, setBorrando] = useState(false);
  const handleEliminar = async (event) => {
    setBorrando(true);
    event.stopPropagation();
    try {
      const response = await axiosClient.delete(
        `${process.env.REACT_APP_API_URL}/keywordset/${elSet._id}`
      );
      toast({
        title: response.data.name + " deleted successfully.",
        description: "We've deleted your campaign data.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setEliminado(true);
      setBorrando(false);
    } catch (error) {
      toast({
        title: "Error deleting " + error.message,
        description: "There was an error deleting your campaign.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setBorrando(false);
    }
  };
  const [eliminado, setEliminado] = useState(false);

  const doInvestigate = async (id) => {
    try {
      setInvestigateResult({});
      setLoadingInvestigation(true);
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_URL}/keywordset/${id}`
      );
      const queries = response.data.keywordId.map((keyword) =>
        keyword.query.replace(/\s+\d{4}-\d{2}-\d{2}$/, "")
      );
      const queryString = queries.join(",");
      const researchUrl = `${process.env.REACT_APP_API_URL}/research/search?id=${id}&q=${queryString}`;

      const researchResponse = await axiosClient.get(researchUrl);

      const newResearchPoints = researchResponse.data.response
        .match(/<li[^>]*>(.*?)<\/li>/g)
        .map((point) => point.replace(/<\/?li>/g, ""));
      const combinedPoints = [...selectedPoints, ...newResearchPoints];

      setInvestigateResult(researchResponse.data);
      const updatedResearch = `<ul>${combinedPoints
        .map((point) => `<li>${point}</li>`)
        .join("")}</ul>`;
      setElSet((currentElSet) => ({
        ...currentElSet,
        research: updatedResearch,
      }));
      setResearchPoints(combinedPoints);
      setSelectedPoints([]); // clear selected points to prevent duplicates
      await saveResearch(id, updatedResearch); // Save the updated research
      setLoadingInvestigation(false);
    } catch (error) {
      console.error(error);
      setLoadingInvestigation(false);
    }
  };

  const updateBlogPostUrl = async (id, url) => {
    try {
      await axiosClient.patch(
        `${process.env.REACT_APP_API_URL}/keywordset/${id}/blogposturl`,
        { url: urlBlogPost }
      );
      toast({
        title: "Blog post URL updated successfully.",
        description: "Your blog post URL has been updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating blog post URL " + error.message,
        description: "There was an error updating your blog post URL.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const saveResearch = async (id, updatedResearch) => {
    try {
      await axiosClient.patch(
        `${process.env.REACT_APP_API_URL}/keywordset/${id}/research`,
        { research: updatedResearch }
      );
      toast({
        title: "Research updated successfully.",
        description: "Your research points have been updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating research " + error.message,
        description: "There was an error updating your research.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [urlBlogPost, setUrlBlogPost] = useState("");
  const handleKWSBlogPostChange = () => {
    updateBlogPostUrl(elSet._id, urlBlogPost);
  };

  const handleCheckboxChange = (point) => {
    setSelectedPoints((prevSelectedPoints) =>
      prevSelectedPoints.includes(point)
        ? prevSelectedPoints.filter((item) => item !== point)
        : [...prevSelectedPoints, point]
    );
    localStorage.setItem("selectedPoints", selectedPoints);
    console.log(selectedPoints);
  };

  const renderResearchPoints = (points) => {
    if (!points) return null;
    return (
      <ul>
        {points.map((point, index) => (
          <li key={index}>
            <Checkbox
              isChecked={selectedPoints.includes(point)}
              onChange={() => handleCheckboxChange(point)}
            >
              {point}
            </Checkbox>
          </li>
        ))}
      </ul>
    );
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const [h2ses, setH2ses] = useState(null);
  const parseAndReplacePoints = (text) => {
    const regex = /```json([\s\S]*)```/;
    const match = text.match(regex);
    if (match) {
      const jsonText = match[1];
      const parsedText = JSON.parse(jsonText);
      const sortedList = Object.values(parsedText).sort();
      console.log("ELPARSEDTEXT", parsedText);

      return parsedText;
    }
    return [];
  };

  const [elArticulo, setElArticulo] = useState("");
  const generarWPArticle = async () => {
    try {
      const elPrompt =
        "Utilizando etiquetas html como h1, h2 y p. Genera un articulo utilizando estos subtitulos: " +
        JSON.stringify(h2ses);
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/research/generico/`,
        { prompt: elPrompt }
      );
      console.log(response.data.choices[0].message.content);
      setElArticulo(response.data.choices[0].message.content);
    } catch (error) {
      console.error("Error generating H2: ", error);
    }
  };

  const guardarContent = async () => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/content/generico/${elSet._id}/wordpress`,
        { text: elArticulo }
      );
      toast({
        title: "Articulo guardado",
        description: "Se ha guardado el articulo en wordpress",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const parseContent = (content) => {
    console.log("el content", content);
    const regex = /<(Text|Heading|h1|h2|p|a)[^>]*>(.*?)<\/\1>/g;
    const matches = content.matchAll(regex);
    const parsedContent = [];
    for (const match of matches) {
      const tag = match[1];
      const text = match[2];
      let element;
      switch (tag) {
        case "Heading":
          element = <Heading margin="15px 0">{text}</Heading>;
          break;
        case "h1":
          element = <Heading margin="15px 0">{text}</Heading>;
          break;
        case "Text":
          element = (
            <Text fontWeight="bold" margin="15px 0">
              {text}
            </Text>
          );
          break;
        case "h2":
          element = (
            <Text fontWeight="bold" margin="15px 0">
              {text}
            </Text>
          );
          break;
        case "p":
          element = <p>{text}</p>;
          break;
        // Add more cases for other tags if needed
        case "a":
          element = <Link href="#">{text}</Link>;
          break;
        default:
          element = null;
          break;
      }

      if (element) {
        parsedContent.push(element);
      }
    }

    return parsedContent;
  };

  return (
    <>
      <AccordionItem style={eliminado ? { display: "none" } : { display: "" }}>
        <h2>
          <AccordionButton
            style={{ background: isOpen ? "#DDD" : "" }}
            onClick={() => {
              handleIsOpen();
            }}
          >
            <Box flex="1" textAlign="left">
              {elSet.name}
            </Box>
            <>
              {cargandoSocialPosts ? (
                <Puntitos />
              ) : (
                <>
                  {hayContenido.twitter && (
                    <Icon as={FaTwitter} color="black" marginRight={5} />
                  )}
                  {hayContenido.linkedin && (
                    <Icon as={FaLinkedin} color="black" marginRight={5} />
                  )}
                  {hayContenido.instagram && (
                    <Icon as={FaInstagram} color="black" marginRight={5} />
                  )}
                  {hayContenido.facebook && (
                    <Icon as={FaFacebook} color="black" marginRight={5} />
                  )}
                  {hayContenido.wordpress && (
                    <Icon as={FaWordpress} color="black" marginRight={5} />
                  )}
                </>
              )}
            </>
            <AccordionIcon />
            <IconButton
              isLoading={borrando}
              icon={<FaTrash />}
              aria-label="Delete"
              onClick={handleEliminar}
            />
          </AccordionButton>
        </h2>
        <AccordionPanel py={5}>
          <KeywordTags
            elSet={elSet}
            removeKeyword={removeKeyword}
            openModal={openModal}
          />
          <FormControl id="campaign-blogposturl" mt={4}>
            <FormLabel>Blog post url:</FormLabel>
            <HStack spacing={4}>
              <Input
                value={urlBlogPost}
                onChange={(e) => setUrlBlogPost(e.target.value)}
                placeholder="Enter Blog Post URL"
              />
              <Button onClick={handleKWSBlogPostChange} mt={2}>
                +
              </Button>
            </HStack>
          </FormControl>
          <ReferenciasForm setId={elSet._id} />
          <VStack align="flex-start">
            <Box style={{ padding: "33px" }}>
              {elSet.research && (
                <>
                  <Box marginBottom={23}>
                    <VStack alignItems="flex-start">
                      <strong>Se escribirá sobre los siguientes puntos:</strong>
                      <small>
                        Marca lo que deseas conservar y vuelve a investigar si
                        no estas conforme
                      </small>
                    </VStack>
                  </Box>
                  <Box>{renderResearchPoints(researchPoints)}</Box>
                </>
              )}
            </Box>
            <HStack>
              <Button
                colorScheme="green"
                isLoading={loadingInvestigation}
                onClick={() => {
                  doInvestigate(elSet._id);
                }}
              >
                Investigar
              </Button>
              {investigateResult.response &&
                investigateResult.id === elSet._id && (
                  <>
                    <TiTick color={"green"} width="20px" />
                    <Button onClick={() => handleAceptar()}>Aceptar</Button>
                    <small>
                      Puedes volver a investigar si no estás conforme
                    </small>
                  </>
                )}
              <HStack mt={"35px"}>
                <Select
                  value={selectedNumber}
                  width="100px"
                  onChange={(e) => setSelectedNumber(parseInt(e.target.value))}
                >
                  {[...Array(20)].map((_, index) => (
                    <option key={index} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </Select>
                <Button
                  onClick={generarH2}
                  my={7}
                  colorScheme="green"
                  isLoading={procesando}
                >
                  Generar {selectedNumber} H2
                </Button>
              </HStack>
            </HStack>
            {h2ses !== null && (
              <Box>
                <Box>
                  <strong>Los H2 generados son:</strong>
                  <Box>
                    <RenderHeadings titles={h2ses} />{" "}
                  </Box>
                </Box>
                <Button onClick={generarWPArticle}>
                  Generar Articulo Completo
                </Button>
                {elArticulo !== "" && (
                  <>
                    <Box>{parseContent(elArticulo)}</Box>
                    <Button onClick={guardarContent}>Guardar</Button>{" "}
                  </>
                )}
              </Box>
            )}
            <Box style={{ width: "100%" }}>
              <Box pb={15}>
                <Divider borderColor="blue.500" />
              </Box>
              <SocialNetworkPosting
                keywordsetId={elSet._id}
                onUpdate={handleUpdateSocialContent}
              />
            </Box>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
      {isModalOpen && (
        <CampaignKeywordsModal
          tableData={tableData}
          isOpen={isModalOpen}
          onClose={(set) => closeModal(set)}
          setId={elSet._id}
          nombreSet={elSet.name}
        />
      )}
    </>
  );
};

export default EditSetsAccordionItem;
