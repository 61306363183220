import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Tag,
  TagCloseButton,
  Checkbox,
  VStack,
  Text,
} from "@chakra-ui/react";

const KeywordTags = ({ elSet, removeKeyword, openModal }) => {
  const [selectedTags, setSelectedTags] = useState(() => {
    // Inicializar el estado con las tags guardadas en localStorage
    const storedTags = localStorage.getItem("titulo|" + elSet._id);
    return storedTags ? storedTags.split(",") : [];
  });

  useEffect(() => {
    // Actualizar localStorage cada vez que cambie selectedTags
    localStorage.setItem("titulo|" + elSet._id, selectedTags.join(","));
  }, [selectedTags]);

  const handleCheckboxChange = (keyword) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(keyword)
        ? prevSelectedTags.filter((tag) => tag !== keyword)
        : [...prevSelectedTags, keyword]
    );
  };

  return (
    <>
      <Box pb={3}>
        <VStack>
          <Text>Grupo de palabras clave:</Text>
          <small>Marca la que deseas usar como titulo del blogpost</small>
        </VStack>
        <Divider borderColor="blue.500" />
      </Box>

      {elSet.keywordId &&
        elSet.keywordId.map((keyword, index) => (
          <Tag key={keyword._id + index} colorScheme="blue" mr={1} mb={1} p={3}>
            <Checkbox
              backgroundColor="white"
              borderColor="gray"
              isChecked={selectedTags.includes(
                keyword.query.replace(/\s+\d{4}-\d{2}-\d{2}$/, "")
              )}
              onChange={() =>
                handleCheckboxChange(
                  keyword.query.replace(/\s+\d{4}-\d{2}-\d{2}$/, "")
                )
              }
              mr={1}
            />
            <small>{keyword.query.replace(/\s+\d{4}-\d{2}-\d{2}$/, "")}</small>
            <TagCloseButton onClick={() => removeKeyword(keyword._id)} />
          </Tag>
        ))}

      <Tag
        color="black"
        border="1px"
        mr={1}
        p={3}
        mb={1}
        style={{ cursor: "pointer" }}
        onClick={openModal}
      >
        <strong>+</strong>
      </Tag>

      <Box pt={3}>
        <Divider borderColor="blue.500" />
      </Box>
    </>
  );
};

export default KeywordTags;
