// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  useToast,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import CoverImage from "assets/img/CoverImage.png";
import React, { useState } from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import AuthCover from "layouts/AuthCover";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";

function SignUpCover() {
  const toast = useToast();

  // Chakra color mode
  const textColor = useColorModeValue("gray.400", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
  });

  const signUpUser = () => {
    setIsSubmitting(true);
    if (newUser.username && newUser.email && newUser.password) {
      axios
        .post(process.env.REACT_APP_API_URL + "/auth/register", newUser)
        .then((response) => {
          toast({
            title: `Usuario registrado ${newUser.username}`,
            description: "Usuario registrado correctamente.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          // Handle success response
          // console.log(response.data);
          setIsSubmitting(false);
        })
        .catch((error) => {
          setIsSubmitting(false);
          // Handle error response
          console.log(error.response);
          toast({
            title: error.response.data.message,
            description: "No se hapodido registrar.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
    setIsSubmitting(false);
  };

  return (
    <AuthCover image={CoverImage}>
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt={{ base: "60px", md: "160px" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          mb={{ base: "20px", md: "auto" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Name
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Your full name"
              mb="24px"
              size="lg"
              onChange={(e) => {
                setNewUser({ ...newUser, username: e.target.value });
              }}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="email"
              placeholder="Your full email adress"
              mb="24px"
              size="lg"
              onChange={(e) => {
                setNewUser({ ...newUser, email: e.target.value });
              }}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="password"
              placeholder="Your password"
              mb="24px"
              size="lg"
              onChange={(e) => {
                setNewUser({ ...newUser, password: e.target.value });
              }}
            />
            <FormControl display="flex" alignItems="center" mb="24px">
              <Switch id="remember-login" colorScheme="blue" me="10px" />
              <FormLabel htmlFor="remember-login" mb="0" fontWeight="normal">
                Remember me
              </FormLabel>
            </FormControl>
            <Button
              fontSize="10px"
              variant="dark"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={signUpUser}
            >
              SIGN UP
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColor} fontWeight="medium">
              Already have an account?
              <RouterLink
                to="/auth/authentication/sign-in/cover"
                style={{
                  color: titleColor,
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                Sign In
              </RouterLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </AuthCover>
  );
}

export default SignUpCover;
