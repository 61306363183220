import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

const TermsOfService = () => (
  <Box p={6}>
    <Heading as="h1" mb={4}>
      Términos de Servicio
    </Heading>
    <Text mb={2}>
      <strong>Fecha de vigencia: 01/01/2023</strong>
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      1. Descripción del Servicio
    </Heading>
    <Text>
      PSEO.TECH proporciona servicios de generación de contenido escrito basado
      en datos de palabras clave proporcionados por los usuarios, con el
      objetivo de mejorar el posicionamiento en Google (el "Servicio").
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      2. Elegibilidad
    </Heading>
    <Text>
      Para utilizar nuestros Servicios, debes ser mayor de edad en tu
      jurisdicción y tener la capacidad legal para aceptar estos Términos.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      3. Uso del Servicio
    </Heading>
    <Text>
      El usuario se compromete a utilizar el Servicio únicamente con fines
      legales y de acuerdo con estos Términos. Nos reservamos el derecho de
      suspender o terminar el acceso al Servicio en cualquier momento y por
      cualquier motivo, incluyendo violaciones de estos Términos.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      4. Propiedad Intelectual
    </Heading>
    <Text>
      Todos los contenidos, marcas, logotipos, gráficos y demás elementos del
      Sitio son propiedad de PSEO.TECH o de sus licenciantes y están protegidos
      por las leyes de propiedad intelectual.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      5. Limitación de Responsabilidad
    </Heading>
    <Text>
      PSEO.TECH no se responsabiliza por ningún daño directo, indirecto,
      incidental o consecuente que resulte del uso o la incapacidad de uso del
      Servicio.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      6. Modificaciones de los Términos
    </Heading>
    <Text>
      Nos reservamos el derecho de modificar estos Términos en cualquier
      momento. Las modificaciones serán efectivas desde el momento en que se
      publiquen en el Sitio. Es responsabilidad del usuario revisar
      periódicamente estos Términos para estar al tanto de cualquier cambio.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      7. Ley Aplicable
    </Heading>
    <Text>
      Estos Términos se rigen por las leyes de [País/Estado], sin tener en
      cuenta sus principios de conflictos de leyes.
    </Text>

    <Heading as="h2" size="md" mt={6} mb={2}>
      8. Contacto
    </Heading>
    <Text>
      Si tienes alguna pregunta sobre estos Términos, por favor contáctanos en{" "}
      <a href="mailto:contacto@app.pseo.tech">contacto@app.pseo.tech</a>.
    </Text>
  </Box>
);

export default TermsOfService;
