import React, { useState, useEffect } from "react";
import { FaPlane } from "react-icons/fa";
import { MdArrowForwardIos } from "react-icons/md";
import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon,
  Tag,
  TagCloseButton,
  VStack,
  Text,
  useToast,
  HStack,
  Icon,
  Button,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { SlRefresh } from "react-icons/sl";
import EditSetsAcordion from "components/Custom/EditSetsAcordion";
import SearchTable3 from "components/Tables/SearchTable3";
import axiosClient from "../../axiosClient.js";

const EditCampaignTabs = ({ tableData, id, lasLandings }) => {
  const toast = useToast();
  const [cargandoSets, setCargandoSets] = useState(true);
  const [keywordSet, setKeywordSet] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const fetchKeywordSetsData = async () => {
    try {
      setCargandoSets(true);
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_URL}/keywordset/campaign-id/${id}`
      );
      const filtrado = response.data.filter((set) => {
        return set.name !== id.toString();
      });
      setKeywordSet(filtrado);
    } catch (error) {
      console.log(error);
      setCargandoSets(false);
    } finally {
      setCargandoSets(false);
    }
  };

  useEffect(() => {
    fetchKeywordSetsData();
  }, []);

  const resetSets = () => {
    fetchKeywordSetsData();
  };

  const columnsData = [
    {
      Header: "ID",
      accessor: "id",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "QUERY",
      accessor: "query",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "CLICKS",
      accessor: "clicks",
      minWidth: "10px",
      width: "15px",
    },
    {
      Header: "IMPR",
      accessor: "impressions",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "CTR",
      accessor: "ctr",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "POSITION",
      accessor: "position",
      minWidth: "15px",
      width: "50px",
    },
    {
      Header: "IMP",
      accessor: "importance",
      minWidth: "50px",
      width: "100px",
    },
    {
      Header: "DATE",
      accessor: "date",
      minWidth: "50px",
      width: "100px",
    },
  ]; // Add your columns data here

  return (
    <Flex
      direction="column"
      pt={57}
      w="100%"
      overflowX={{ sm: "scroll", md: "hidden" }}
    >
      <Tabs>
        <TabList>
          <Tab onClick={() => setCargandoSets(false)}>
            Keywords de la campaña
          </Tab>
          <Tab
            onClick={fetchKeywordSetsData}
            width="221px"
            justifyContent="space-between"
          >
            Sets de keywords
            {!cargandoSets ? <SlRefresh marginLeft={"33px"} /> : null}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {tableData && tableData.length > 0 && (
              <SearchTable3
                tableData={tableData}
                columnsData={columnsData}
                anyId={id}
                onSave={resetSets}
              />
            )}
          </TabPanel>
          <TabPanel>
            <EditSetsAcordion
              keywordSet={keywordSet}
              cargandoSets={cargandoSets}
              lasLandings={lasLandings}
              tableData={tableData}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default EditCampaignTabs;
