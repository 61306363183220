import React, { useState, useEffect } from "react";
import { useMediaQuery, Box, Text, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const MobileWarning = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isVisible, setIsVisible] = useState(true);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const storedCounter = sessionStorage.getItem("mobileWarningCounter");
    if (storedCounter) {
      setCounter(parseInt(storedCounter));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("mobileWarningCounter", counter);
  }, [counter]);

  const handleDismiss = () => {
    setIsVisible(false);
    setCounter(counter + 1);
  };

  if (!isMobile || !isVisible || counter >= 2) {
    return null;
  }

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="rgba(0, 0, 0, 0.8)"
      color="white"
      zIndex="1000"
      p="4"
      textAlign="center"
    >
      <Box position="relative" maxWidth="600px">
        <IconButton
          position="absolute"
          top="1"
          right="1"
          icon={<CloseIcon />}
          onClick={handleDismiss}
          bg="transparent"
          color="white"
          _hover={{ bg: "rgba(255, 255, 255, 0.2)" }}
        />
        {counter !== 0 && (
          <Text fontSize="xl" fontWeight="bold" py={5}>
            ¡Ultima Advertencia y un analgesico 💊! 🤦‍♂️
          </Text>
        )}
        <Text fontSize="xl" fontWeight="bold">
          SOLO NOS RESPONSABILIZAMOS DE LA USABILIDAD DEL MODO DESKTOP Y
          RECOMENDAMOS DESKTOP.
        </Text>
        {counter !== 0 && (
          <Text fontSize="xl" fontWeight="bold" py={5}>
            MOBILE: SIGUE BAJO TU PROPIA RESPONSABILIDAD. Y SUERTE!
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default MobileWarning;
