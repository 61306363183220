import { Box, Flex, keyframes } from "@chakra-ui/react";

const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
`;

const Dot = () => (
  <Box
    as="span"
    mx="1"
    h="8px"
    w="8px"
    bg="currentColor"
    borderRadius="full"
    display="inline-block"
    animation={`${bounce} 1.4s infinite ease-in-out both`}
  />
);

const Puntitos = () => (
  <Flex color="blue.500">
    <Dot />
    <Dot style={{ animationDelay: "-0.16s" }} />
    <Dot style={{ animationDelay: "-0.32s" }} />
  </Flex>
);

export default Puntitos;
