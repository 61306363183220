import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";
import axios from "axios";

function OAuthRedirectPage() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const exchangeCodeForToken = async () => {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");
      const userId = localStorage.getItem("userId");
      try {
        // Llamada al backend para intercambiar el código por un token
        const response = await axios.get(
          `https://be.pseo.tech/oauth/callback?code=${code}&userId=${userId}`
        );

        const { access_token, refresh_token } = response.data;
        // Aquí puedes guardar el accessToken en localStorage, Redux store, etc.
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);

        // Redirige al usuario a la página principal o dashboard
        history.push("/admin/pages/profile/overview");
      } catch (error) {
        console.error("Error during token exchange:", error);
        history.push("/login"); // En caso de error, redirigir al login
      }
    };

    exchangeCodeForToken();
  }, [location, history]);

  return (
    <Box textAlign="center" paddingTop="20px">
      <Spinner size="xl" />
      <p>Processing authentication...</p>
    </Box>
  );
}

export default OAuthRedirectPage;
