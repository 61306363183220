import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  VStack,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  FormLabel,
  FormControl,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  Spinner,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Radio,
  RadioGroup,
  IconButton,
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { SelectBar } from "components/Navbars/SelectBar/SelectBar";
import { MdReplay, MdCheck } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { RiEqualizerFill } from "react-icons/ri";

import {
  FaCheckCircle,
  FaTimesCircle,
  FaUndoAlt,
  FaPlus,
} from "react-icons/fa";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import axiosClient from "../../axiosClient";

function SearchTable2(props) {
  const toast = useToast();
  const { columnsData, tableData, projectId } = props;
  const [data, setData] = useState(tableData);

  const [dateFilter, setDateFilter] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(() => columnsData, []);

  const [checkedState, setCheckedState] = useState({});
  const [checkedId, setCheckedId] = useState([]);

  const handleCheckboxChange = (id) => {
    setCheckedId((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
    setCheckedState((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    state,
  } = tableInstance;

  const createPages = (current, total) => {
    const delta = 2; // Número de páginas antes y después de la actual
    const range = [];

    for (
      let i = Math.max(2, current - delta);
      i <= Math.min(total - 1, current + delta);
      i++
    ) {
      range.push(i);
    }

    if (current - delta > 2) {
      range.unshift("...");
    }
    if (current + delta < total - 1) {
      range.push("...");
    }

    range.unshift(1); // Siempre añade la primera página
    if (total > 1) range.push(total); // Añade la última página si hay más de una página

    return range;
  };

  const [campaignOptions, setCampaignOptions] = useState([]);
  const fetchCampaignIds = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_URL}/campaign/project/${projectId}`
      );
      //  console.log(response.data);
      const newCampaignOptions = response.data.map((item) => ({
        id: item._id,
        name: item.nombre,
      }));
      setCampaignOptions(newCampaignOptions);
      // Save the response in state
      // Example: setCampaignIds(response.data);
    } catch (error) {
      console.error("Error fetching campaign ids:", error);
    }
  };

  const [saving, setSaving] = useState(false);
  const postKeywordSet = async (keywordSet) => {
    try {
      setSaving(true);
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/keywordset/`,
        { campaignId: selectedCampaign, keywordId: keywordSet }
      );
      // console.log(response.data);
      toast({
        title: "Keyword set guardado",
        status: "success",
        duration: 1500,
        isClosable: true,
      });
      return response.data;
    } catch (error) {
      toast({
        title: "Error guardando el keyword set",
        status: "error",
        duration: 1500,
        isClosable: true,
      });

      console.error("error guardando el keyword set:", error);
    } finally {
      setSaving(false);
    }
  };

  const handleCreateGroup = () => {
    postKeywordSet(checkedId, selectedCampaign);
  };

  useEffect(() => {
    setPageSize(500);
    fetchCampaignIds();
  }, [setPageSize]);

  const [selectedCampaign, setSelectedCampaign] = useState("");

  const { pageIndex, pageSize, globalFilter } = state;

  const applyDateFilter = (range) => {
    const currentDate = new Date();
    let filteredData = tableData;

    const getDateFromRange = (range) => {
      const newDate = new Date(currentDate);
      switch (range) {
        case "1d":
          newDate.setDate(currentDate.getDate() - 1);
          break;
        case "3d":
          newDate.setDate(currentDate.getDate() - 3);
          break;
        case "1w":
          newDate.setDate(currentDate.getDate() - 7);
          break;
        case "15d":
          newDate.setDate(currentDate.getDate() - 15);
          break;
        case "1m":
          newDate.setMonth(currentDate.getMonth() - 1);
          break;
        case "3m":
          newDate.setMonth(currentDate.getMonth() - 3);
          break;
        default:
          return null;
      }
      return newDate;
    };

    const dateThreshold = getDateFromRange(range);

    if (dateThreshold) {
      filteredData = tableData.filter(
        (item) => new Date(item.date) >= dateThreshold
      );
    }

    // console.log("Filtered Data", filteredData);
    setData(filteredData);
    setDateFilter(range);
    onClose();
  };

  const clearDateFilter = () => {
    setData(tableData);
    setDateFilter(null);
  };

  const [allChecked, setAllChecked] = useState(false);

  const handleSelectAllChange = () => {
    setAllChecked(!allChecked);
    setCheckedState((prevCheckedState) => {
      const newCheckedState = {};
      page.forEach((row) => {
        const rowId = row.original.id;
        newCheckedState[rowId] = !allChecked;
      });
      return newCheckedState;
    });

    setCheckedId((prevCheckedId) => {
      if (!allChecked) {
        return page.map((row) => row.original.id);
      }
      return [];
    });
  };

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack>
          <Flex justify="space-between" align="center" w="100%" px="22px">
            <Stack
              direction={{ sm: "column", md: "row" }}
              spacing={{ sm: "4px", md: "12px" }}
              align="center"
              me="12px"
              my="24px"
              minW={{ sm: "100px", md: "200px" }}
            >
              <Select
                variant="main"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                color="gray.500"
                size="sm"
                borderRadius="12px"
                maxW="75px"
                cursor="pointer"
              >
                <option>50</option>
                <option>100</option>
                <option>200</option>
                <option>500</option>
              </Select>
              <Text fontSize="xs" color="gray.400" fontWeight="normal">
                Entradas por pagina
              </Text>
            </Stack>
            <Stack
              direction={{ sm: "column", md: "row" }}
              spacing={{ sm: "4px", md: "12px" }}
              align="center"
              me="12px"
              my="24px"
              minW={{ sm: "100px", md: "200px" }}
            >
              {projectId !== 0 && campaignOptions && (
                <>
                  <SelectBar
                    options={campaignOptions}
                    value={selectedCampaign}
                    onChange={(e) => setSelectedCampaign(e.target.value)}
                  />

                  <IconButton
                    variant="no-effects"
                    icon={saving ? <Spinner /> : <FaPlus />}
                    color="black"
                    border="1px solid black"
                    isDisabled={checkedId.length === 0 || !selectedCampaign}
                    onClick={() => handleCreateGroup()}
                  />
                </>
              )}
              <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={{ sm: "4px", md: "12px" }}
                align="center"
                me="12px"
                my="24px"
                minW={{ sm: "100px", md: "200px" }}
              >
                <Flex
                  align={{ sm: "flex-start", lg: "flex-end" }}
                  justify={{ sm: "flex-start", lg: "flex-end" }}
                  w="100%"
                  px="22px"
                  mb="36px"
                >
                  <SearchBar
                    border="1px solid "
                    borderColor={useColorModeValue("gray.200", "gray.600")}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    searchActive={false}
                  />
                  <IconButton
                    icon={<RiEqualizerFill />}
                    onClick={onOpen}
                    ml="2"
                    aria-label="Filter"
                  />
                  {dateFilter && (
                    <Tag
                      size="md"
                      borderRadius="full"
                      variant="solid"
                      colorScheme="blue"
                      ml="4"
                    >
                      <TagLabel>{`Filtrado: ${dateFilter}`}</TagLabel>
                      <TagCloseButton onClick={clearDateFilter} />
                    </Tag>
                  )}
                </Flex>
              </Stack>
            </Stack>
          </Flex>
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            textAlign="left"
            width="100%"
            mb={{ sm: "24px", md: "0px" }}
          >
            Mostrando {pageSize * pageIndex + 1} -{" "}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{" "}
            de {tableData.length} entradas
          </Text>
        </VStack>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    key={index}
                    {...column.getHeaderProps(
                      index !== 0 ? column.getSortByToggleProps() : undefined
                    )}
                    pe="0px"
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {index === 0 ? (
                        <Checkbox
                          size="lg"
                          isChecked={allChecked}
                          onChange={handleSelectAllChange}
                          colorScheme="blue"
                        />
                      ) : (
                        column.render("Header")
                      )}
                      <Icon
                        w={{ sm: "10px", md: "14px" }}
                        h={{ sm: "10px", md: "14px" }}
                        color={columns.isSorted ? "gray.500" : "gray.400"}
                        float="right"
                        as={
                          column.isSorted
                            ? column.isSortedDesc
                              ? TiArrowSortedDown
                              : TiArrowSortedUp
                            : TiArrowUnsorted
                        }
                      />
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              const rowId = row.original.id;
              return (
                <Tr
                  {...row.getRowProps()}
                  key={index}
                  bg={checkedState[rowId] ? "blue.100" : "white"}
                >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "STATUS") {
                      data = (
                        <Flex align="center">
                          <Flex
                            justify="center"
                            align="center"
                            border="1px solid"
                            borderColor={
                              cell.value === "Paid"
                                ? "green.400"
                                : cell.value === "Refunded"
                                ? "gray.400"
                                : "red.400"
                            }
                            borderRadius="50%"
                            width="24px"
                            height="24px"
                            me="6px"
                          >
                            <Icon
                              as={
                                cell.value === "Paid"
                                  ? MdCheck
                                  : cell.value === "Refunded"
                                  ? MdReplay
                                  : IoMdClose
                              }
                              color={
                                cell.value === "Paid"
                                  ? "green.400"
                                  : cell.value === "Refunded"
                                  ? "gray.400"
                                  : "red.400"
                              }
                              w="20px"
                              h="20px"
                            />
                          </Flex>
                          <Text>{cell.value}</Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "ID") {
                      data = (
                        <Flex align="center">
                          <FormControl display="flex" alignItems="center">
                            <Checkbox
                              size="lg"
                              isChecked={checkedState[rowId]}
                              onChange={() => handleCheckboxChange(rowId)}
                              colorScheme="blue"
                              me="8px"
                            />
                            <FormLabel
                              mt="3"
                              style={{
                                overflow: "hidden",
                                maxWidth: "75px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                            ></FormLabel>
                          </FormControl>
                        </Flex>
                      );
                    } else if (cell.column.Header === "DATE") {
                      data = (
                        <Text style={{ minWidth: "75px" }}>{cell.value}</Text>
                      );
                    } else if (cell.column.Header === "QUERY") {
                      data = (
                        <Flex align="center">
                          <Text
                            style={
                              checkedState[rowId]
                                ? {
                                    fontWeight: "700",
                                    color: "black",
                                    minWidth: "175px",
                                  }
                                : { fontWeight: "300", minWidth: "175px" }
                            }
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "CLICKS") {
                      data = <Text>{cell.value}</Text>;
                    } else if (cell.column.Header === "IMPR") {
                      data = <Text>{cell.value}</Text>;
                    } else if (cell.column.Header === "CTR") {
                      data = <Text>{parseFloat(cell.value).toFixed(2)}</Text>;
                    } else if (cell.column.Header === "POSITION") {
                      data = <Text>{parseFloat(cell.value).toFixed(2)}</Text>;
                    } else if (cell.column.Header === "IMP") {
                      data = <Text>{parseFloat(cell.value).toFixed(2)}</Text>;
                    }

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          w="100%"
          px={{ md: "22px" }}
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: "24px", md: "0px" }}
          >
            Mostrando {pageSize * pageIndex + 1} a{" "}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{" "}
            de {tableData.length} entradas
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            {pageIndex > 0 && (
              <Button onClick={() => gotoPage(0)} disabled={pageIndex === 0}>
                {"<<"}
              </Button>
            )}
            {createPages(pageIndex + 1, pageCount).map((pageNumber, index) => {
              if (pageNumber === "...") {
                return (
                  <Button key={index} disabled>
                    {pageNumber}
                  </Button>
                );
              } else {
                return (
                  <Button
                    key={index}
                    variant="no-effects"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="8px"
                    bg={pageNumber === pageIndex + 1 ? "blue.500" : "#fff"}
                    border={
                      pageNumber === pageIndex + 1
                        ? "none"
                        : "1px solid lightgray"
                    }
                    _hover={{ opacity: "0.7", borderColor: "gray.500" }}
                  >
                    <Text
                      fontSize="sm"
                      color={pageNumber === pageIndex + 1 ? "#fff" : "gray.600"}
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              }
            })}
            {pageIndex < pageCount - 1 && (
              <Button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={pageIndex === pageCount - 1}
              >
                {">>"}
              </Button>
            )}
          </Stack>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Seleccionar Rango de Fechas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={applyDateFilter}>
              <Stack direction="column">
                <Radio value="1d">1 Día</Radio>
                <Radio value="3d">3 Días</Radio>
                <Radio value="1w">1 Semana</Radio>
                <Radio value="15d">15 Días</Radio>
                <Radio value="1m">1 Mes</Radio>
                <Radio value="3m">3 Meses</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SearchTable2;
