import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import React from "react";

const AcordionWrapper = ({ children, titulo }) => {
  return (
    <Accordion allowToggle>
      <AccordionItem style={{ border: "none" }}>
        <h2>
          <AccordionButton>{titulo}</AccordionButton>
        </h2>
        <AccordionPanel>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AcordionWrapper;
